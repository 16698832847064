import FechaFormato from "../util/FechaFormato";

const GridUniversidades = ({data, gridAccion, selectable = false, onSelectAccion=null}) => {
  return (
    <div className="table-responsive">
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Nombre universidad</th>
            <th scope="col">Pais</th>
            <th scope="col">Fecha de creación</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} 
            className={"row-button"}
            >
              <td>{item.id} </td>
              <td>{item.nombre} </td>
              <td>{item.pais} </td>
              <td>{FechaFormato(item.created_at, "corto")} </td>
              <td>
                {selectable &&
                <div className="row">
                  <div className="col-md-6">
                    <button
                      onClick={() => onSelectAccion(item)}
                      className="btn btn-sm btn-success"
                    >
                      <span className="fa fa-arrow-right"></span> Elegir
                    </button>  
                  </div>
                  <div className="col-md-5">
                    <button
                    onClick={() => gridAccion(item)}
                    className="btn btn-lg "
                  >
                    <span className="fa fa-bars"></span>
                  </button>
                  </div>
                </div>
                }
                {!selectable &&
                <button
                  onClick={() => gridAccion(item)}
                  className="btn btn-sm btn-warning"
                >
                  <span className="fa fa-eye"></span> Detalle
                </button>

                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GridUniversidades;
