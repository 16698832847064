import GridUniversidades from "../forms/GridUniversidades";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import React, { useState, useRef, useEffect } from "react";
import Pagination2 from "../Layout/Pagination2";
import Modal from "../UI/Modal";
import FormUniversidad from "../forms/FormUniversidad";
import Notification from "../UI/Notification";
import SearchBarUniversidad from "../UI/SearchBarUniversidad";

configure({ axios: axiosClient });

const URL_UNIV = "/certificados/universidades";

const PageUniversidades = ({selectUniversidad=false, onSelectUniversidad=null}) => {
  const modal = useRef();
  const formUniversidad = useRef();
  const [savingPerson, setSavingPerson] = useState(false);
  const [editar, setEditar] = useState(false);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(5);
  const [filtro, setFiltro] = useState({
    page_number: 1,
    page_size: 10,
    nombre: "",
  });
  const [{ data, loading, error }, fetchLista] = useAxios(
    {
      url: URL_UNIV,
      method: "GET",
    },
    { useCache: false, manual: true }
  );

  const handleSetPage = (page) => {
    //setCurrentPage(page);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = page;
      return nuevo;
    });
  };
  const handleFiltro = (pfiltro) => {
    console.log(pfiltro);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.sede = pfiltro.sede;
      nuevo.rol = pfiltro.rol;
      return nuevo;
    });
  };

  const handleBusqueda = (busqueda) => {
    //console.log(busqueda);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.nombre = busqueda.nombre;
      nuevo.pais = busqueda.pais;
      return nuevo;
    });
  };

  const handleModalPersona = () => {
    setEditar(true);
    setButtons(buttonsNewPersona);
    modal.current.Abrir();
  };
  const handleDialogoAceptar = () => {
    setSavingPerson(true);
    formUniversidad.current.Aceptar().then(
      (res) => {
        fetchLista({
          params: filtro,
        });
        modal.current.Cerrar();
        formUniversidad.current.Cancelar();
        setSavingPerson(false);
        Notification(
          "success",
          "Se registro la información correctamente",
          "Correcto"
        );
      },
      (error) => {
        setSavingPerson(false);
        if (typeof error === "object") {
          alert(error.response.data.message);
        } else alert("Se encontraron errores:\n" + error);
      }
    );
  };
  const handleDialogoCancelar = () => {
    formUniversidad.current.Cancelar();
    modal.current.Cerrar();
  };

  const buttonsNewPersona = (
    <>
      <button onClick={handleDialogoCancelar} className="btn btn-secondary">
        Cancelar
      </button>
      <button onClick={handleDialogoAceptar} className="btn btn-primary">
        Aceptar
      </button>
    </>
  );
  

  const buttonsDetallePersona = (
    <>
      <button onClick={handleDialogoCancelar} className="btn btn-secondary">
        Cerrar
      </button>
      <button
        onClick={() => {
          setEditar(true);
          setButtons(buttonsActualizarPersona);
        }}
        className="btn btn-warning"
      >
        Editar
      </button>
    </>
  );

  const buttonsActualizarPersona = (
    <>
      <button onClick={handleDialogoCancelar} className="btn btn-secondary">
        Cancelar
      </button>
      <button onClick={handleDialogoAceptar} className="btn btn-primary">
        Guardar
      </button>
    </>
  );

  const [buttons, setButtons] = useState(buttonsNewPersona);

  const handleGridAccion = (persona) => {
    //setSelectedPersona(persona);
    setEditar(false);
    setButtons(buttonsDetallePersona);
    formUniversidad.current.SetData(persona);
    modal.current.Abrir();
  };

 useEffect(() => {
    fetchLista({
      params: filtro,
    }).catch(() => {
      //console.log(error);
      console.log("ocurrio un error");
    });
  }, [filtro]);

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <h5>Lista de universidades registradas</h5>
        </div>
        <div className="card-body">
            <div className="text-center">
              <button
                onClick={handleModalPersona}
                className="btn btn-primary mb-2"
              >
                +<span className="fa fa-school"></span> Registrar universidad
              </button>
            </div>

            <p>
              <a
                className="btn btn-primary"
                data-toggle="collapse"
                href="#collapseFilterUniversidad"
                role="button"
                aria-expanded="false"
                aria-controls="collapseFilterUniversidad"
              >
                Buscar <i className="fa fa-solid fa-filter"></i>
              </a>
            </p>
            <div className="collapse" id="collapseFilterUniversidad">
              <div className="card card-body">
                  <SearchBarUniversidad setBusqueda={handleBusqueda} />
                </div>
            </div>

          {!loading && data ? (
            <>
              <GridUniversidades
                selectable ={selectUniversidad}
                data={data.lista}
                gridAccion={handleGridAccion}
                onSelectAccion={onSelectUniversidad}
              />
              <Pagination2
                nPages={Math.ceil(data.size / filtro.page_size)}
                currentPage={filtro.page_number}
                setCurrentPage={handleSetPage}
              />
            </>
          ) : (
            <div className="text-center m-5">
              <div className="spinner-border mt-5" role="status"></div>
              <p>Cargando lista... </p>
            </div>
          )}
        </div>
      </div>
      <Modal
        handleDialogoClose={handleDialogoCancelar}
        titulo={"Datos universidad"}
        ref={modal}
        loading={savingPerson}
        buttons={buttons}
      >
        <FormUniversidad
          ref={formUniversidad}
          showButtons={false}
          editar={editar}
        />
        {/* <FormPersona ref={formPersona} showButtons={false} editar={editar}   /> */}
      </Modal>
    </div>
  );
};

export default PageUniversidades;
