import React, {  useState } from 'react';

const SearchBar = ({setBusqueda}) => {
  const [nombre, setNombre] = useState("");
  const [ci, setCi] = useState("");
  
  let minPermitido=3;

  const handleBuscar = ()=>{
    let salida = {
      nombre,
      ci ,
    }
    setBusqueda(salida);
  }
  const handleBorrar = ()=>{
    setNombre("");
    setCi("");
    let salida = {
      nombre:"",
      ci:"" ,
    }
    setBusqueda(salida);
  }

  let btnActivado = true;
  if((nombre.length < minPermitido && nombre.length > 0) || (ci.length < minPermitido && ci.length > 0)){
    btnActivado=false;
  }
  let nombreEstilo={};
  if(nombre.length < minPermitido && nombre.length > 0){
    nombreEstilo = {outline:"red 1px solid"};
  }
  let ciEstilo={};
  if(ci.length < minPermitido && ci.length > 0){
    ciEstilo = {outline:"red 1px solid"};
  }

  return (
    <div className="row align-items-end">
      <div className="col-md-6">
        <label className="col-form-label">Nombre:</label>
        <input
          className="form-control"
          type="text"
          //ref={nombre}
          onChange={(e)=>setNombre(e.target.value)}
          style={nombreEstilo}
          value={nombre}
        />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Documento de identidad:</label>
        <input
          className="form-control"
          type="text"
          //ref={ci}
          onChange={(e)=>setCi(e.target.value)}
          style={ciEstilo}
          value={ci}
        />
      </div>
      <div className="col-md-2">
        <div className="btn-group">

          <button className='btn btn-default' onClick={handleBorrar} >Borrar</button>
          <button className="btn btn-primary" onClick={handleBuscar} disabled={btnActivado ? null : "disabled"}>
            <i className="fa fa-search"></i> Buscar
          </button>
        </div>

      </div>
    </div>
  );
};

export default SearchBar;
