import { Link, Outlet } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import { useState } from "react";
import LoadOverLay from "./LoadOverlay";

configure({ axios: axiosClient });

const Navbar = ({ dataUsuario, handleEndSession }) => {
  const listas_index = window.can("certificados.listas.index|admin");
  const emision_index = window.can("certificados.emision.index|admin");

  const [logout, setLogout] = useState(false);
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: "/user/logout",
      method: "GET",
    },
    { manual: true }
  );

  const onBtnCerrarSesion = async () => {
    setLogout(true);
    await fetch();
    handleEndSession(false);
    //window.location='/signin';
  };
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-azuloscuro">
        <div className="navbar-brand">Sistema de certificados RRII</div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to="/home" className="nav-link">
                Inicio
              </Link>
            </li>
            {/*{emision_index && (
              <li className="nav-item">
                <Link to="/documentos" className="nav-link">
                  Emitir certificado
                </Link>
              </li>
            )}
             {listas_index && (
              <li className="nav-item">
                <Link to="/listas" className="nav-link">
                  Registros
                </Link>
              </li>
            )}
            {listas_index && (
              <li className="nav-item">
                <Link to="/firmas" className="nav-link">
                  Firmas
                </Link>
              </li>
            )} */}
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown user-menu">
              <a
                href="/#"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={dataUsuario.picture}
                  className="user-image img-circle elevation-2"
                  alt={dataUsuario.name}
                  referrerPolicy="no-referrer"
                />
                <span className="d-none d-md-inline">{dataUsuario.name}</span>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                style={{ left: "inherit", right: "0px" }}
              >
                <li
                  className="user-header bg-primary"
                  style={{ height: "inherit" }}
                >
                  <img
                    src={dataUsuario.picture}
                    className="img-circle elevation-2"
                    alt={dataUsuario.name}
                    referrerPolicy="no-referrer"
                  />

                  <p>
                    {dataUsuario.name} - {dataUsuario.roles[0].name}
                    <small>{dataUsuario.email}</small>
                  </p>
                </li>
                <li className="user-footer p-1">
                  {/* <a href="/#" className="btn btn-default btn-flat">
                    Configuración
                  </a> */}
                  <a
                    href="/#"
                    className="btn btn-danger btn-block float-right"
                    onClick={onBtnCerrarSesion}
                  >
                    <span className="fa fa-key pr-2"></span>
                    Cerrar sesión
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      {logout && <LoadOverLay mensaje={"Cerrando sesión..."} />}

      <div className="mt-5 pt-4">
        <Outlet />
      </div>
      {/* <footer class="footer mt-auto py-3 bg-light">
        <div class="container">
          <span class="text-muted">Place sticky footer content here.</span>
        </div>
      </footer> */}
    </>
  );
};

export default Navbar;
