import { Link } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import certHome from "../../assets/certificado2.jpg";

configure({ axios: axiosClient });

const HomePage = ({ data }) => {
  const PER_EMITIDOS = window.can("certificados.emision.index|admin");
  const PER_PERSONAS = window.can("mod_persona|admin");
  const PER_UNIVERSIDADES = window.can("mod_universidad|admin");
  const PER_SOLICITUD = window.can("mod_solicitud|admin");
  const PER_EMISION = window.can("mod_emision|admin");



  return (
    <div className="container">
      <div className="card">
        <div className="card-body row">
          <div className="col-md-5">
            <div className="jumbotron bg-info">
              <h2>Bienvenido! </h2>
              <h4>{data.name}</h4>
              <p className="lead">
                Bienvenido al sistema de emisión certificados <strong>RRII </strong> 
                Unifranz, aqui podra realizar la emision de los documentos
              </p>
              
              {PER_PERSONAS && (
                <p>
                  <Link to="/personas" className="btn btn-light">
                    Personas »
                  </Link>
                </p>
              )}
              {PER_UNIVERSIDADES && (
                <p>
                  <Link to="/universidades" className="btn btn-light">
                    Universidades »
                  </Link>
                </p>
              )}
              {PER_SOLICITUD && (
                <p>
                  <Link to="/solicitudes" className="btn btn-light">
                    Solicitudes »
                  </Link>
                </p>
              )}
            </div>
          </div>
          <div className="col-md-7">
            <div className="jumbotron row m-1 p-1 ">
              
                <img style={{width:"100%"}} src={certHome} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
