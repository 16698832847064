import AWN from "awesome-notifications";
import "awesome-notifications/dist/style.css";

const Notification = (tipo, mensaje='', titulo="Mensaje", tiempo=5) => {
  let notifier = new AWN();
  tiempo = tiempo*1000;
  switch(tipo){
    default:
    case "tip":
      notifier.tip(mensaje,  {
        labels: {
          tip: titulo,
        },
        durations: { tip: tiempo} 
      });
    break;
    case "info":
      notifier.info(mensaje,  {
        labels: {
          info: titulo,
        },
         durations: { info: tiempo} 
      });
    break;
    case "success":
      notifier.success(mensaje,  {
        labels: {
          success: titulo,
        },
        durations: { success: tiempo} 
      });
    break;
    case "warning":
      notifier.warning(mensaje,  {
        labels: {
          warning: titulo,
        },
        durations: { warning: tiempo} 
      });
    break;
    case "alert":
      notifier.alert(mensaje,  {
        labels: {
          alert: titulo,
        },
        durations: { alert: tiempo} 
      });
    break;
    
  }
  
  
}
 
export default Notification;