import {PARAMETROS} from "../userdata/Parametros";
import SelectRol from "./SelectRol";

let salida = {
  metodologia: "0",
  sede:"",
}

const FilterBarSolicitud = ({setFiltro}) => {
  const handleChange = (e, componente)=>{
    //console.log(e.target.value, componente);
    salida[componente] = e.target.value;
    setFiltro(salida);
  }
  if(PARAMETROS){
    return (     
      <div className="row align-items-end">
        <div className="col-md-2">
          <label>Sede:</label>
          <SelectRol onChange={(e)=>handleChange(e,"sede")} titulo={"sede:"} data={PARAMETROS.SEDE} first={{codigo:"", valor:" - Todos -"}} />     
        </div>

        <div className="col-md-4">
          <label>Metodología:</label> 
          <SelectRol onChange={(e)=>handleChange(e,"metodologia")} data={PARAMETROS.METODOLOGIA} first={{codigo:"0", valor:"- Todos -"}} />                 
        </div>
      </div> 
    );
  }
}
 
export default FilterBarSolicitud;