import { useState, useRef, useEffect } from 'react';
import plantilla_estudiante_low from "../../assets/plantilla_estudiante_prev.jpg";
import plantilla_docente_low from "../../assets/plantilla_docente_prev.jpg";
import Modal from '../UI/Modal';
import useAxios, { configure } from "axios-hooks";
import axiosClient from '../../api/axiosClient';
import Notification from '../UI/Notification';

configure({ axios: axiosClient });

const URL_PREVIA = "/certificados/solicitud/vistafinal";
const URL_REENVIO = "/certificados/solicitud/emitir_certificado_individual";

var validRegexEmail = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/ ;

const DetalleEmitidos = ({ solicitud}) => {
  const [persona, setPersona] = useState();
  const [correoAlt, setCorreoAlt] = useState("");
  const [correoSeleccionado, setCorreoSeleccionado] = useState(1);
  
  const FormularioRow = (label, component) => {
    return (
      <>
        <dt className="col-md-4 col-sm-12 ">{label}</dt>
        <dd className="col-md-8 col-sm-12 ">{component}</dd>
      </>
    );
  };

  const modal = useRef();
  const modalReenvio = useRef();

  const handleModalVistaFinal =(e, id_solicitud, id_persona)=>{
    e.preventDefault();
    modal.current.Abrir();
    fetchPrevia({params:{
      id_solicitud,
      id_persona,
    }}).then(null, error=>{
      Notification(
        "alert",
        typeof error === "object"? error.response.data.message :"Se encontraron errores:\n" + error,
        "Error"
      );
    });
  }
  const handleCerrarModal = ()=>{    
    modal.current.Cerrar();
  }
  const [{ data, loading, error }, fetchPrevia] = useAxios(
    {
      url:  URL_PREVIA,
      method: "GET",
      params: {
        id_solicitud:solicitud.id     
      }
    },
    { manual: true }
  );
  const [{ data: rdata, loading: rloading, error: rerror }, fetchReenviarCorreo] = useAxios(
    {
      url:  URL_REENVIO,
      method: "GET",
      params: {
        id_solicitud: solicitud.id,
        id_persona: persona?.id,
        correo: correoAlt.trim()==="" ? null: correoAlt      
      }
    },
    { manual: true }
  );
  const handleModalReenviar = (persona)=>{
    
    setPersona(persona);
    modalReenvio.current.Abrir();
  }
  const handleModalReenviarCerrar = ()=>{
    setCorreoSeleccionado(1);
    setCorreoAlt('');
    modalReenvio.current.Cerrar() ;
  }
  const handleReenviar = ()=>{
    
    if(correoSeleccionado===2 ){
      if(correoAlt.trim()===""){
          alert("Debe escribir una dirección de correo electrónico");
        return;
      }
      else if(!correoAlt.match(validRegexEmail)){
        alert("Debe escribir una dirección de correo electrónico con formato valido");
        return;
      }
    }
    
    fetchReenviarCorreo().then((res)=>{
      Notification(
        "success",
        "Se envio el correo electrónico",
        "Correcto"
      );
      modalReenvio.current.Cerrar() ;
    }, error=>{
      Notification(
        "alert",
        typeof error === "object"? error.response.data.message :"Se encontraron errores:\n" + error,
        "Error"
      );
    });

    
  }
  // useEffect(()=>{
  //   setCorreoSeleccionado(1);
  // },[solicitud])

  return (
    <div> 
      <div className='text-center h5 mb-3'>Información del certificado</div>
          <div className="card ">
            {/* <div className="card-header h5  text-left">Información del certificado</div> */}
            <div className="card-body">
              <div className="row">
                  <dl className="row col-md-7">
                    {FormularioRow("Sede:", solicitud.sede_valor)}
                    {FormularioRow("Metodología:", solicitud.metodologia_valor)}
                    {FormularioRow("Título:", solicitud.titulo)}
                    {FormularioRow("Universidad Par:",solicitud.universidad_valor)}
                    {FormularioRow("Horas académicas:", solicitud.horas)}
                    {FormularioRow("Participantes:", solicitud.personas.length)}                    
                  </dl>
                  <dl className="row col-md-5">
                    
                    {FormularioRow("Creado por:", solicitud.creador +" "+ solicitud.created_at)}
                    {FormularioRow("Modificado por:", solicitud.modificador +" "+solicitud.updated_at)}
                    {FormularioRow("Fecha de emisión:",solicitud.fecha_emision)}
                  </dl>
              </div>

            </div>

          </div>

        <div className='text-center h5 mb-3'>Participantes</div>
      {solicitud.personas.map((persona) => (
        <div key={persona.id} className={persona.aprobado?"card bg-info":"card bg-gray"}>
          <div className="card-header ">
            <div className="row">
            <div className="col-md-4">
              No. Identificación {persona.ci}
            </div>
              <div className="col-md-4">
                {!persona.aprobado &&
                  <div className='text-center text-warning h5'>
                    <span className="fa fa-file mr-2"></span>
                    Certificado emitido</div> 
                }
   
              </div>
              <div className="col-md-4"><div className="text-right">
                
                  <button className="btn btn-info " onClick={()=>handleModalReenviar(persona)}>
                    <span className='fa fa-paper-plane mr-2'></span>
                    Reenviar certificado</button>                  
                
            </div></div>
            </div>
            
            
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-8 ">
                <dl className="row mb-1  pt-2 ">
                  {FormularioRow("Rol:", persona.tipo_rol_valor)}
                  {FormularioRow("Nombre participante:", persona.nombre_completo)}
                  {FormularioRow("Género:", persona.genero)}
                  {FormularioRow("Correo electrónico:", persona.email)}
                  {FormularioRow("Carrera:", persona.carrera)}
                  {FormularioRow(<div > Enlance Qr:</div>,<a target='_blank' rel='noreferrer' className='text-bold text-warning' href={persona.url}>Verificar <span className='fa fa-qrcode'></span></a> )}
                </dl>
              </div>
              {/* <div className="col-md-5">
                <dl className="row mb-1 pt-2 ">
                  
                </dl>
              </div> */}
              <div className="col-md-3">
                <a href="/#" onClick={(e)=>handleModalVistaFinal(e,solicitud.id, persona.id)}>
                  <img 
                  src={persona.tipo_persona === "1" ?plantilla_estudiante_low: plantilla_docente_low} 
                  alt="Vista previa" 
                  className='img-fluid img_previa' />              

                </a> 
              </div>
            </div>
          </div>
        </div>
      ))}
      <Modal ref={modal} 
        titulo="Vista del certificado" 
        loading={false} 
        
        buttons={<button onClick={handleCerrarModal} className='btn btn-secondary'>Cerrar</button>} 
        size={3} 
      >
        <div style={{minHeight:"400px"}} className='bg-dark text-center p-1'>
          {loading && 
            <div className="text-center m-5">
              <div className="spinner-border mt-5 mb-4" role="status"></div>
              <p className="text-secondary h5">Generando documento... </p>
            </div> 
          }
          {!loading &&
          //<img style={{width:"100%"}}  src={data}  alt="vista previa" />
            <embed 
              type="application/pdf"
              src={data}
              width="100%"
              height="650px"
              title='documento.pdf'
            /> 
          }
        </div>
         
      </Modal> 
      <Modal ref={modalReenvio}
      titulo="Reenviar certificado" 
      handleDialogoClose={handleModalReenviarCerrar}
      loading={rloading} 
      loadingText={"Enviando certificado..."}
      size={2}
      buttons={
        <>
      <button onClick={handleModalReenviarCerrar } className='btn btn-secondary'>Cancelar</button>
      <button onClick={handleReenviar} className='btn btn-danger'>
        {/* <span className='fa fa-envelope mr-1'></span> */}
        <span className='fa fa-paper-plane mr-1'></span>
        Reenviar ahora</button>
        </>
    } 
      >
        {persona && 
          <div className="container">
            <div className="h5">Certificado No. {solicitud.id}</div>
            <dl className="row mb-1  pt-2 ">
              {FormularioRow("Nombre participante:", persona.nombre_completo)}
              {FormularioRow("Documento de identidad:", persona.ci)}
              <hr />
              <div className='col-md-12 mt-3 mb-2'>
              Se reenviara el certificado a la siguiente dirección de correo electrónico:
              </div>
              {FormularioRow(
                <div className="custom-control custom-radio">
                  <input className="custom-control-input" type="radio" id="customRadio1" name="customRadio" checked={correoSeleccionado===1}  onChange={()=>{setCorreoSeleccionado(1); setCorreoAlt('')}} />
                  <label htmlFor="customRadio1"  className="custom-control-label">Correo electrónico actual:</label>
                </div>
                , <div >{persona.email}</div> )}
              {FormularioRow(
                <div className="custom-control custom-radio">
                <input className="custom-control-input" type="radio" id="customRadio2" name="customRadio" checked={correoSeleccionado===2}  onChange={()=>setCorreoSeleccionado(2)} />
                <label htmlFor="customRadio2" className="custom-control-label">Correo electrónico secundario:</label>
              </div>
              , <><input className='form-control' disabled={correoSeleccionado===1? "disabled":null} type="text" value={correoAlt} onChange={(e)=>setCorreoAlt(e.target.value)} />
              <sup>Verifique que el correo electrónico sea valido antes de enviar.</sup>
              </> )}
            </dl>
          </div>
        }
      </Modal>
    </div>
  );
};

export default DetalleEmitidos;
