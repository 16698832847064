import GridPersonas from "../forms/GridPersonas";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import Pagination2 from "../Layout/Pagination2";
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import FilterBar from "../UI/FilterBar";
import SearchBar from "../UI/SearchBar";
import Modal from "../UI/Modal";
import FormPersona from "../forms/FormPersona";
import Notification from "../UI/Notification";
import GridPersonasSeleccionar from "../forms/GridPersonasSeleccionar";

configure({ axios: axiosClient });

const URL_LISTA = "/certificados/personas";

const PagePersonas = forwardRef(({ selectPersona = false, sede=null, onSelectPersona = null}, ref) => {
  const modal = useRef();
  const formPersona = useRef();
  const [savingPerson, setSavingPerson] = useState(false);
  const [editar, setEditar] = useState(false);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(5);
  const [filtro, setFiltro] = useState({
    page_number: 1,
    page_size: 10,
    sede: sede,
    rol: 0,
    nombre: "",
    ci: "",
  });
  const [{ data, loading, error }, fetchLista] = useAxios(
    {
      url: URL_LISTA,
      method: "GET",
    },
    { useCache: false, manual: true }
  );

  const handleSetPage = (page) => {
    //setCurrentPage(page);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = page;
      return nuevo;
    });
  };
  const handleFiltro = (pfiltro) => {
    console.log(pfiltro);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.sede =  pfiltro.sede;
      nuevo.rol = pfiltro.rol;
      return nuevo;
    });
  };

  const handleBusqueda = (busqueda) => {
    //console.log(busqueda);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.nombre = busqueda.nombre;
      nuevo.ci = busqueda.ci;
      return nuevo;
    });
  };

  const handleModalPersona = () => {
    setEditar(true);
    setButtons(buttonsNewPersona);
    modal.current.Abrir();
  };
  const handleDialogoAceptar = () => {
    setSavingPerson(true);
    formPersona.current.Aceptar().then(
      (res) => {
        fetchLista({
          params: filtro,
        });
        modal.current.Cerrar();
        formPersona.current.Cancelar();
        setSavingPerson(false);
        Notification(
          "success",
          "Se registro la información correctamente",
          "Correcto"
        );
      },
      (error) => {
        setSavingPerson(false);
        if (typeof error === "object") {
          alert(error.response.data.message);
        } else alert("Se encontraron errores:\n" + error);
      }
    );
  };
  const handleDialogoCancelar = () => {
    formPersona.current.Cancelar();
    modal.current.Cerrar();
  };

  const buttonsNewPersona = (
    <>
      <button onClick={handleDialogoCancelar} className="btn btn-secondary">
        Cancelar
      </button>
      <button onClick={handleDialogoAceptar} className="btn btn-primary">
        Aceptar
      </button>
    </>
  );
  const [buttons, setButtons] = useState(buttonsNewPersona);

  const buttonsDetallePersona = (
    <>
      <button onClick={handleDialogoCancelar} className="btn btn-secondary">
        Cerrar
      </button>
      <button
        onClick={() => {
          setEditar(true);
          setButtons(buttonsActualizarPersona);
        }}
        className="btn btn-warning"
      >
        Editar
      </button>
    </>
  );

  const buttonsActualizarPersona = (
    <>
      <button onClick={handleDialogoCancelar} className="btn btn-secondary">
        Cancelar
      </button>
      <button onClick={handleDialogoAceptar} className="btn btn-primary">
        Guardar
      </button>
    </>
  );

  const handleGridAccion = (persona) => {
    //setSelectedPersona(persona);
    setEditar(false);
    setButtons(buttonsDetallePersona);
    formPersona.current.SetData(persona);
    modal.current.Abrir();
  };
/*=========================SELECCION ==============================*/

const [seleccionados, setSeleccionados] = useState([]);

useImperativeHandle(ref,()=>{
  return {
    Reset(){
      data.lista.forEach(item => {
        item.agregado = false;     
      });
      setSeleccionados([]);
    },
    GetSeleccionados(){
      return seleccionados;
    },
    SetPersonasSeleccionadas(inicialSeleccionados=[]){
      inicialSeleccionados.forEach(element => {
        element.agregado=true;
      });
      setSeleccionados(inicialSeleccionados);
    }
  }
},[data, seleccionados])

  const onAgregar = (persona) => {
    //persona.agregado=true;
    const nuevaPersona = {...persona};
    setSeleccionados((prev) => {
      nuevaPersona.agregado=true;
      //persona.agregado = true;
      const nuevo = [...prev, nuevaPersona];
      return nuevo;
    });
  };
  const onQuitar = (persona) => {
    data.lista.forEach(item => {
        if(item.id === persona.id){
          item.agregado = false;
        }
      
    });
    let salida = seleccionados.filter((valor) => {
      if (valor.id !== persona.id) {
        return valor;
      }
    });
    setSeleccionados(salida);
  };


  if(data && onSelectPersona){
    data.lista.forEach(item => {
      seleccionados.forEach(item2 => {
        if(item.id === item2.id){
          item.agregado = item2.agregado;
        }
      });
    });
  }

  useEffect(() => {
    fetchLista({
      params: filtro,
    }).catch(() => {
      //console.log(error);
      console.log("ocurrio un error");
    });
  }, [filtro]);

  useEffect(() => {
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.sede = sede 
      return nuevo;
    });
  }, [sede]);

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <h5>Lista de personas</h5>
        </div>
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <button
                onClick={handleModalPersona}
                className="btn btn-block btn-primary"
              >
                +<span className="fa fa-user"></span> Crear persona
              </button>
            </div>
          </div>

          <p>
            <a
              className="btn btn-primary"
              data-toggle="collapse"
              href="#collapseFilterPersona"
              role="button"
              aria-expanded="false"
              aria-controls="collapseFilterPersona"
            >
              Buscar y filtrar <i className="fa fa-solid fa-filter"></i>
            </a>
          </p>
          <div className="collapse" id="collapseFilterPersona">
            <div className="card card-body">
              <SearchBar setBusqueda={handleBusqueda} />
              <FilterBar setFiltro={handleFiltro} filtroSede={sede} />
            </div>
          </div>
          {!selectPersona && (
            <>
              {!loading && data ? (
                <>
                  <GridPersonas
                    dataPersonas={data.lista}
                    gridAccion={handleGridAccion}
                    selectable={selectPersona}
                    onSelectAccion={onSelectPersona}
                  />
                  <Pagination2
                    nPages={Math.ceil(data.size / filtro.page_size)}
                    currentPage={filtro.page_number}
                    setCurrentPage={handleSetPage}
                  />
                </>
              ) : (
                <div className="text-center m-5">
                  <div className="spinner-border mt-5" role="status"></div>
                  <p>Cargando lista... </p>
                </div>
              )}
            </>
          )}
          {selectPersona && (
            <div className="row">
              <div className="col-md-6">
                <h5>Disponibles</h5>
                {!loading && data ? (
                  <>
                    <Pagination2
                      nPages={Math.ceil(data.size / filtro.page_size)}
                      currentPage={filtro.page_number}
                      setCurrentPage={handleSetPage}
                    />
                    <GridPersonasSeleccionar
                      dataPersonas={data.lista}
                      gridAccion={handleGridAccion}
                      onSelectAccion={onAgregar}
                    />
                    <Pagination2
                      nPages={Math.ceil(data.size / filtro.page_size)}
                      currentPage={filtro.page_number}
                      setCurrentPage={handleSetPage}
                    />
                  </>
                ) : (
                  <div className="text-center m-5">
                    <div className="spinner-border mt-5" role="status"></div>
                    <p>Cargando lista... </p>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <h5>Asignados para el certificado</h5>
                <GridPersonasSeleccionar key={data}
                  modo="quitar"
                  dataPersonas={seleccionados}
                  gridAccion={handleGridAccion}
                  onSelectAccion={onQuitar}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        handleDialogoClose={handleDialogoCancelar}
        titulo={"Datos persona"}
        ref={modal}
        loading={savingPerson}
        buttons={buttons}
      >
        <FormPersona ref={formPersona} showButtons={false} editar={editar} />
      </Modal>
    </div>
  );
});

export default PagePersonas;
