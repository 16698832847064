import SelectRol from "../UI/SelectRol";
import {PARAMETROS} from "../userdata/Parametros";
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import FormularioRow, { FrLabelValue } from "../Layout/FormularioRow";
import Modal from "../UI/Modal";
import PageUniversidades from "../paginas/PageUniversidades";
import PagePersonas from "../paginas/PagePersonas";

configure({ axios: axiosClient });

let nuevaSolicitud={
  id:0,
  id_universidad: "0",
  universidad_valor:"",
  sede :"0",
  metodologia:"0",
  titulo:"",
  horas:"",
  no_personas:0,
  ids_personas:""
}
const URL_GUARDAR_SOLICITUD = "/certificados/solicitud/guardar";
const URL_ACTUALIZAR_SOLICITUD = "/certificados/solicitud/actualizar";

const FormSolicitud = forwardRef(({ modo="lectura"}, ref) => {
  
  const [solicitud, setSolicitud] = useState({...nuevaSolicitud});
  const [URL_API, setURL_API] = useState(URL_GUARDAR_SOLICITUD);

  const modalUniv = useRef();
  const modalPersonas = useRef();
  const pagePersonasRef=useRef();
  const [personasSeleccionadas, setPersonasSeleccionadas] = useState([]);
  
  useImperativeHandle(ref, ()=>{
    return {
      Aceptar(){
        return Guardar();
      },
      Cancelar(){
        setURL_API(URL_GUARDAR_SOLICITUD);
        Reset();
      },
      SetData(data){
        setURL_API(URL_ACTUALIZAR_SOLICITUD);
        setSolicitud(data);
      }
    }
  },[solicitud])

  const handleSolicitud=(parametro, e)=>{
    let valor = e.target.value;
    //nuevaPersona[parametro] = valor
    setSolicitud(prev =>{
      let nuevo = {...prev};
      nuevo[parametro] = valor;
      return nuevo;
    });

    //console.log(nuevaPersona);
  }

  const [{ data, loading, error }, fetchGuardar] = useAxios(
    {
      url:  URL_API,
      method: "POST",
      params: {
        id:solicitud.id,
        id_universidad: solicitud.metodologia ==="3"? "0" :solicitud.id_universidad,
        sede: solicitud.sede,
        metodologia: solicitud.metodologia,
        titulo: solicitud.titulo,
        horas: solicitud.horas,
        //ids_personas: (personasSeleccionadas.map((item)=>item.id)).join(','),
        ids_personas: solicitud.ids_personas,
        no_personas: solicitud.no_personas
      }
    },
    { manual: true }
  );

  const Guardar = ()=>{
    let valido=true;
    let message ="";

    return new Promise((resolve, reject)=>{
      
      if(solicitud.sede === "0"){
        message+="falta sede\n";
        valido=false;
      }
      if(solicitud.metodologia === "0"){
        message+="falta metodologia\n";
        valido=false;
      }
      if(solicitud.titulo.trim() === ""){
        message+="falta titulo\n";
        valido=false;
      }
      if(solicitud.metodologia !=="3" && solicitud.id_universidad === "0"){
        message+="falta universidad\n";
        valido=false;
      }
      if(solicitud.horas === ""){
        message+="falta horas\n";
        valido=false;
      }
      if(solicitud.ids_personas === ""){
        message+="falta personas\n";
        valido=false;
      }
      if(valido){
        resolve(valido)
        
      }else{
        reject(message);
        //alert(message);
      }
    }).then(response =>{
      // fetchGuardar().then(()=>{
      //   message+="Guardado correctamente\n";
      // })
      //console.log("La respuesa es" + response);
      return fetchGuardar({});
    })

  }
  const Reset=()=>{
    setSolicitud({...nuevaSolicitud});
    handleCancelarModalPersonas();
  }
  const handleSelectedUniversidad=(universidad)=>{
    setSolicitud(prev=>{
      const nuevo = {...prev};
      nuevo.id_universidad = universidad.id;
      nuevo.universidad_valor = universidad.nombre;
      return nuevo;
    });
    modalUniv.current.Cerrar();
  }
  const handleModalPersonas = ()=>{
    if(solicitud.sede ==="0"){
      alert("Primero seleccione una sede");
      return;
    }
    modalPersonas.current.Abrir(); 
    pagePersonasRef.current.SetPersonasSeleccionadas(solicitud.personas) 
  }
  const botonCerrarModUniversidad=<button className="btn btn-secondary" onClick={()=>{modalUniv.current.Cerrar()}}>Cerrar</button>
  const handleCancelarModalPersonas=()=>{
    pagePersonasRef.current.Reset();
    modalPersonas.current.Cerrar();
    setPersonasSeleccionadas([]);
  }
  const handleGetSeleccionados = ()=>{
    let per_seleccionados = pagePersonasRef.current.GetSeleccionados();
    //setPersonasSeleccionadas(per_seleccionados);
    modalPersonas.current.Cerrar();

    setSolicitud(prev=>{
      const nuevo = {...prev};
      nuevo.ids_personas = (per_seleccionados.map((item)=>item.id)).join(',');
      nuevo.no_personas = per_seleccionados.length;
      nuevo.personas = per_seleccionados;
      return nuevo;
    });
  }
  const PersonasPorSolicitud =(<>
    <a
      className="btn btn-sm btn-primary btn-block"
      data-toggle="collapse"
      href="#collapsePersonas"
      role="button"
      aria-expanded="false"
      aria-controls="collapsePersonas"
      >
      {/* {solicitud.no_personas} Personas */}
      {solicitud.personas && solicitud.personas.length} Personas
      <i className="fa fa-caret-down ml-2"></i>
    </a>
    {solicitud.personas && 
      <div className="collapse" id="collapsePersonas">
        <div className="card card-body">
          <ul>
            {solicitud.personas.map(persona=>(
                <li key={persona.id}>{persona.nombre_completo} ({PARAMETROS.TIPO_PERSONA.filter(tipo=>
                  +tipo.codigo ===  +persona.tipo_persona
                )[0].valor })</li>
            ))}
          </ul>
        </div>
      </div>                 
    
    }
  </>);

  if(PARAMETROS)
    return ( 
      <div className="container">
         {
          (modo ==="editar" || modo === "nuevo") &&
          <FormularioRow >
            {/* <FrLabelValue
              label={"Universidad:"}
              component={<SelectRol titulo={"Universidad Par:"} 
              data={[{codigo:"1", valor:"NUR"}]} 
              onChange={(e)=>handleSolicitud("id_universidad",e)} 
              first={{codigo:"0",valor:"Seleccionar"}} 
              value={solicitud.id_universidad} />}
            /> */}
            
            <FrLabelValue 
              label={"Sede:"} 
              component={
                
                  <SelectRol 
                    data={PARAMETROS.SEDE} 
                    onChange={(e)=>handleSolicitud("sede",e)} 
                    first={{codigo:"0",valor:"Seleccionar"}} 
                    value={solicitud.sede} 
                    disabled={modo==="nuevo" ? null : "disabled"}
                  />           
              } />
            <FrLabelValue 
              label={"Metodologia:"} 
              component={
                <SelectRol 
                  data={PARAMETROS.METODOLOGIA} 
                  onChange={(e)=>handleSolicitud("metodologia",e)} 
                  first={{codigo:"0",valor:"Seleccionar"}} 
                  value={solicitud.metodologia} 
                />
              } />
            <FrLabelValue
              label={"Titulo del certificado:"}
              component={<input onChange={e=>handleSolicitud("titulo",e)} className="form-control" type="text" value={solicitud.titulo} />}
            />
            {solicitud.metodologia !== "3" && 
            
            <FrLabelValue
              label={"Universidad Par:"}
              component={
              <>
              {solicitud.universidad_valor}
              <button onClick={()=>{modalUniv.current.Abrir()}} className="btn btn-sm btn-info">Seleccionar...</button>
              </>
            }
            />
          }
            <FrLabelValue
              label={"Horas:"}
              component={<input onChange={e=>handleSolicitud("horas",e)} className="form-control" type="number" value={solicitud.horas}  />}
            />
            <FrLabelValue
              label={"Personas asignadas:"}
              component={
              <>
              {PersonasPorSolicitud}
              <button 
              onClick={handleModalPersonas} 
              className="btn btn-sm btn-info">Seleccionar...</button>
              
              
              </>
            }
            />
          </FormularioRow>          
         } 
        {
          modo ==="lectura" &&
          <FormularioRow>
            <FrLabelValue 
              label={"Universidad Par:"} 
              component={ solicitud.universidad_valor} />
            <FrLabelValue 
              label={"Sede:"} 
              component={ solicitud.sede_valor} />
            <FrLabelValue
              label={"Metodologia:"}
              component={solicitud.metodologia_valor}
            />
            <FrLabelValue
              label={"Titulo del certificado:"}
              component={solicitud.titulo}
            />
            <FrLabelValue
              label={"Horas:"}
              component={solicitud.horas}
            />
            <FrLabelValue
              label={"Personas asignadas:"}
              component={PersonasPorSolicitud}
            />
            
            <FrLabelValue
              label={"Creado por:"}
              component={<>{solicitud.creador}<br/>{solicitud.created_at}</>}
            />
            <FrLabelValue
              label={"Modificado por:"}
              component={<>{solicitud.modificador}<br/>{solicitud.updated_at}</>}
            />
          </FormularioRow>
        }

      <Modal titulo="Elegir universidad" handleDialogoClose={()=>modalUniv.current.Cerrar()} ref={modalUniv} buttons ={botonCerrarModUniversidad}>
        <PageUniversidades selectUniversidad={true} onSelectUniversidad={handleSelectedUniversidad} />
      </Modal>
      <Modal handleDialogoClose={handleCancelarModalPersonas} ref={modalPersonas} size={3} titulo={"Elegir personas"}
        buttons={
          <>
          <button className="btn btn-secondary" onClick={handleCancelarModalPersonas}>Cancelar</button>
          <button className="btn btn-primary" onClick={handleGetSeleccionados}>Aceptar</button>
          </>
        } 
      >
        <PagePersonas selectPersona={true} onSelectPersona={handleSelectedUniversidad} ref={pagePersonasRef} sede={solicitud.sede==="0" ? null : solicitud.sede } />
      </Modal>
      </div>
    );
});
 
export default FormSolicitud;