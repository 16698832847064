import axios from "axios";
import AWN from "awesome-notifications";
import "awesome-notifications/dist/style.css";

const global_url_gateway = process.env.REACT_APP_URL;
const axiosClient = axios.create({
  baseURL: global_url_gateway,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      console.log(window.location.href);
      let loc = window.location.href.split("/");
      console.log("_" + loc[loc.length - 1] + "_");
      if (error.response.status === 401 && loc[loc.length - 1] !== "signin") {
        console.log("debe loguearse!!!");
        let notifier = new AWN();
        let onOk = () => {
          window.location = "/signin";
          //notifier.info('You pressed OK')
        };
        notifier.confirm("Por favor vuelva a iniciar sesión.", onOk, false, {
          labels: {
            confirm: "Sesión expirada",
          },
        });
      } else if (error.response.status === 500) {
        let notifier = new AWN();
        if (typeof error.response.data.message != "undefined") {
          new AWN().alert(error.response.data.message, {
            durations: { alert: 7000 },
          });
        } else notifier.alert(error.message, { durations: { alert: 7000 } });
      }
    } catch (error) {
      console.log("error en la respuesta");
    }
    
    return Promise.reject(error);
  }
);

/*
//TODAVIA NO SE UTILIZA ESTE COMPONENTE
const AxiosInterceptor = ({ children }) => {

  const navigate = useNavigate();

  useEffect(() => {

      const resInterceptor = response => {
          return response;
      }

      const errInterceptor = error => {
        console.log("no autorizado");
          if (error.response.status === 401) {
              navigate('/signin');
          }

          return Promise.reject(error);
      }


      const interceptor = axiosClient.interceptors.response.use(resInterceptor, errInterceptor);

      return () => axiosClient.interceptors.response.eject(interceptor);

  }, [navigate])

  return children;
}
*/
export default axiosClient;
//export { AxiosInterceptor }
