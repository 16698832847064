import TabSolicitudesPendientes from "../forms/TabSolicitudesPendientes";
import TabSolicitudesEmitidas from "../forms/TabSolicitudesEmitidas";
import { useRef } from "react";

const PageSolicitudes = () => {
  const refTabEmitidos= useRef();

  const handleOnEmitir =()=>{
    refTabEmitidos.current.Actualizar();
  }
  return (
    
          <div className="card m-2 bg-light">
            <div className="card-header ">
              <h4>Solicitudes</h4>
            </div>
            <ul className="nav nav-tabs ">
              
                <li className="nav-item">
                  <button
                    className="nav-link active"
                    aria-current="page"
                    data-toggle="tab"
                    href="#tab_1"
                  >
                    Pendientes de emisión
                  </button>
                </li>
             
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-toggle="tab"
                    href="#tab_2"
                  >
                    Emitidos
                  </button>
                </li>
              
            </ul>
            <div className="card-body">
              <div className="tab-content">
                <div className="tab-pane active" id="tab_1">
                  <TabSolicitudesPendientes onEmitir={handleOnEmitir} />
                </div>

                <div className="tab-pane" id="tab_2">
                  <TabSolicitudesEmitidas ref ={refTabEmitidos}  />
                </div>

              </div>

            </div>
          </div>
  );
  
}
 
export default PageSolicitudes;