import FechaFormato from "../util/FechaFormato";

const GridPersonas = ({ dataPersonas, gridAccion }) => {
  if (dataPersonas)
    return (
      <div className="table-responsive">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th>No.</th>

              <th>Sede</th>

              <th>Documento de identidad</th>
              <th>Nombre completo</th>
              <th>Rol persona</th>

              <>
                <th>Correo electrónico</th>
                <th>Fecha</th>
              </>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataPersonas.map((item) => (
              <tr key={item.id} className={"row-button"}>
                <td>{item.id} </td>

                <td>{item.sede_valor} </td>

                <td>{item.ci} </td>
                <td>{item.nombre_completo} </td>
                <td>{item.tipo_rol_valor} </td>

                <>
                  <td>{item.email} </td>
                  <td>{FechaFormato(item.created_at, "corto")} </td>
                </>

                <td>
                  <button
                    onClick={() => gridAccion(item)}
                    className="btn btn-sm btn-warning"
                  >
                    <span className="fa fa-eye"></span> Detalle
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};

export default GridPersonas;
