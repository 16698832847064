import ReactPaginate from "react-paginate";
const Pagination2 = ({ nPages, currentPage, setCurrentPage }) => {
  const handlePageClick = (event) => {
    console.log(event.selected);
    setCurrentPage(event.selected + 1);
  };
  const node = (<h2>siguiente </h2>);
  if (nPages !== 0)
    return (
      <>
        <ReactPaginate
          forcePage={currentPage - 1}
          breakLabel="..."
          nextLabel={<span className="fa fa-chevron-right"></span>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          pageCount={nPages}
          previousLabel={<span className="fa fa-chevron-left"></span>}
          renderOnZeroPageCount={true}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </>
    );
};

export default Pagination2;
