const LoadOverLay = ({ mensaje }) => {
  return (
    <div className="wrapper">
      <div className="overlay">
        <div className="spinner-wrapper text-center">
          <div className="spinner-border text-success" role="status"></div>
          <p>{mensaje}</p>
        </div>
      </div>
    </div>
  );
};

export default LoadOverLay;
