import Pagination2 from "../Layout/Pagination2";
import GridSolicitudes from "../forms/GridSolicitudes";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import React, { useState, useEffect, useRef } from "react";
import Modal from "../UI/Modal";
import FormSolicitud from "../forms/FormSolicitud";
import Notification from "../UI/Notification";
import SearchBarSolicitud from "../UI/SearchBarSolicitud";
import FilterBarSolicitud from "../UI/FilterBarSolicitud";
import GridEmision from "../forms/GridEmision";

configure({ axios: axiosClient });
const URL_SOLICITUDES = "/certificados/solicitudes";
const URL_EMISION = "/certificados/solicitud/emitir_certificados";

const TabSolicitudesPendientes = ({onEmitir}) => {
  const modal = useRef();
  
  const formSolicitud = useRef();
  const [savingPerson, setSavingPerson] = useState(false);
  const [modo, setModo] = useState("lectura");
  
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(5);
  const [filtro, setFiltro] = useState({
    page_number: 1,
    page_size: 10,
    sede: "",
    metodologia: 0,
    titulo: "",
  });
  const [{ data, loading, error }, fetchLista] = useAxios(
    {
      url: URL_SOLICITUDES,
      method: "GET",
    },
    { useCache: false, manual: true }
  );

  const handleSetPage = (page) => {
    //setCurrentPage(page);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = page;
      return nuevo;
    });
  };
  const handleFiltro = (pfiltro) => {
    console.log(pfiltro);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.sede = pfiltro.sede;
      nuevo.metodologia = pfiltro.metodologia;
      return nuevo;
    });
  };

  const handleBusqueda = (busqueda) => {
    //console.log(busqueda);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.titulo = busqueda.nombre;
      return nuevo;
    });
  };

  const handleModalPersona = ()=>{
    setModo("nuevo");
    setButtons(buttonsNewPersona);
    modal.current.Abrir();
  }
  const handleDialogoAceptar=()=>{
    setSavingPerson(true);
    formSolicitud.current.Aceptar()
    .then(res =>{
      fetchLista({
        params: filtro,
      });
      modal.current.Cerrar();
      formSolicitud.current.Cancelar();
      setSavingPerson(false);
      Notification('success','Se registro la información correctamente','Correcto');
    }, error =>{      
      setSavingPerson(false);
      if(typeof error ==="object"){
        alert(error.response.data.message);
      }else
        alert("Se encontraron errores:\n" +error);
      
    });
  }
  const handleDialogoCancelar=()=>{
    formSolicitud.current.Cancelar();
    modal.current.Cerrar();
  }

  const buttonsNewPersona =<><button
    onClick={handleDialogoCancelar}
    className="btn btn-secondary"
  >
    Cancelar
  </button>
  <button
    onClick={handleDialogoAceptar}
    className="btn btn-primary"
    >
    Aceptar
  </button></>;
  const [buttons, setButtons] = useState(buttonsNewPersona);

  const buttonsDetallePersona = <><button
  onClick={handleDialogoCancelar}
  className="btn btn-secondary"
>
  Cerrar
</button>
<button
  onClick={()=>{setModo("editar"); setButtons(buttonsActualizarPersona);}}
  className="btn btn-warning"
  >
  Editar
</button></>;

const buttonsActualizarPersona =<><button
    onClick={handleDialogoCancelar}
    className="btn btn-secondary"
  >
    Cancelar
  </button>
  <button
    onClick={handleDialogoAceptar}
    className="btn btn-primary"
    >
    Guardar
  </button></>;

  const handleGridAccion =(persona)=>{    
    //setSelectedPersona(persona);
    setModo("lectura");
    setButtons(buttonsDetallePersona);
    formSolicitud.current.SetData(persona);
    modal.current.Abrir();
  }

  useEffect(() => {
    fetchLista({
      params: filtro,
    }).catch(() => {
      //console.log(error);
      console.log("ocurrio un error");
    });
  }, [filtro]);
/* ------------------ EMISION ------------------ */
const [solicitudActual, setSolicitudActual] = useState();
const [paginaActual, setPaginaActual] = useState(1);
const [aprobados, setAprobados] = useState(false);
const handleGridEmision = (persona)=>{
  setPaginaActual(2);
    persona.personas.forEach(per =>{
      per.aprobado = false;
    });

  setSolicitudActual(persona);
  
};

const FormularioRow = (label, component) => {
  return (
    <>
      <dt className="col-sm-4">{label}</dt>
      <dd className="col-sm-8 ">{component}</dd>
    </>
  );
};

const [{ data: edata, loading: eloading, error: eerror }, fetchEmitir] = useAxios(
  {
    url: URL_EMISION,
    method: "GET",
  },
  { useCache: false, manual: true }
);

const Emitir =()=>{
  if(aprobados){
    fetchEmitir({
      params:{
        id_solicitud: solicitudActual.id,
        fecha_emision:''
      }
    }).then(res =>{
      Notification("info","Se proceso la solicitud","Exito");
      fetchLista({
        params: filtro,
      });
      
      onEmitir();      
    }, error => {
      //console.log(error);
      Notification("alert", 
        typeof error === "object"? error.response.data.message :"Se encontraron errores:\n" + error,
        "Error");
      console.log("ocurrio un error");
    });
    setPaginaActual(3);
  }else{
    alert("Debe marcar como aprobado cada uno de los certificados antes de emitir.")
  }
};
const handleRegresar = ()=>{ 
    setPaginaActual(1);
}
const handleCancelarEmision=()=>{  
  setPaginaActual(1);
}
if(paginaActual === 1)
  return (    
  <>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <button onClick={handleModalPersona} className="btn btn-block btn-primary">
                +<span className="fa fa-image"></span> Nueva solicitud
              </button>
            </div>
          </div>
          <div className=" h5 mt-4">Lista de solicitudes</div>
          <p>
            <a
              className="btn btn-primary"
              data-toggle="collapse"
              href="#collapseFilterPendientes"
              role="button"
              aria-expanded="false"
              aria-controls="collapseFilterPendientes"
            >
              Buscar y filtrar <i className="fa fa-solid fa-filter"></i>
            </a>
          </p>
          <div className="collapse" id="collapseFilterPendientes">
            <div className="card card-body">
              <SearchBarSolicitud setBusqueda={handleBusqueda} />
              <FilterBarSolicitud setFiltro={handleFiltro} />
            </div>
          </div>

          {!loading && data ? (
            <>
              <GridSolicitudes data={data.lista} gridAccion={handleGridAccion} gridEmision={handleGridEmision} />
              <Pagination2
                nPages={Math.ceil(data.size / filtro.page_size)}
                currentPage={filtro.page_number}
                setCurrentPage={handleSetPage}
              />
            </>
          ) : (
            <div className="text-center m-5">
              <div className="spinner-border mt-5" role="status"></div>
              <p>Cargando lista... </p>
            </div>
          )}
      <Modal handleDialogoClose={handleDialogoCancelar} titulo={"Datos solicitud"} ref={modal} loading={savingPerson} buttons={buttons}>
        <FormSolicitud ref={formSolicitud} modo={modo}   />
      </Modal>
      
      </>
   );
if(paginaActual === 2)
  return(
    <div className="card m-2 bg-light">
        <div className="card-header bg-primary">
        <div className="h5 m-0">
            <button onClick={handleRegresar} className="btn btn-light mr-3"> <span className="fa fa-arrow-left"></span> Regresar</button> 
            Emisión de certificados</div>
        </div>
        <div className="card-body">
        <div className="text-center">
          <div className="h3 text-bold text-primary">Revisión de solicitud </div>
          <h5>Es necesario que revise la información antes de emitir los certificados</h5>
          <div className="card">
            {/* <div className="card-header h6 text-primary text-left">Cabecera de certificado</div> */}
            <div className="row justify-content-center">
              <div className=" col-md-9 col-lg-8">
                <dl className="row mb-1 pt-2 mt-3 mb-4 text-left">
                  {FormularioRow("Sede:", solicitudActual.sede_valor)}
                  {FormularioRow("Metodología:", solicitudActual.metodologia_valor)}
                  {FormularioRow("Título:", solicitudActual.titulo)}
                  {FormularioRow("Universidad Par:",solicitudActual.universidad_valor)}
                  {FormularioRow("Horas académicas:", solicitudActual.horas)}
                </dl>
              </div>
            </div>

          </div>

          <h5>Se emitira el certificado {} para los siguientes participantes:</h5>

        </div>
        <GridEmision solicitud={solicitudActual} setAprobados={setAprobados} />
        <div className="row">
          <div className="col-md-6">
            <button className="btn btn-lg btn-secondary " onClick={handleCancelarEmision}>
              <span className="fa fa-xmark mr-2"> &#x2716;</span>
              Cancelar</button>
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-lg btn-danger" onClick={Emitir}>
              <span className="fa fa-paper-plane mr-2"></span>
              Emitir certificados</button>
          </div>
        </div>
        
        
        </div>

    </div>  
  )
  if(paginaActual === 3)
    return (
    <div className="card m-2 bg-light">
        <div className="card-header bg-info">
          
          <div className="h5">
            <button onClick={handleRegresar} className="btn btn-secondary mr-3"> <span className="fa fa-arrow-left"></span> Regresar</button> 
            Emisión de certificados</div>
        </div>
        <div className="card-body">
          <div className="text-center">
            {eloading ? (
            <>
              
              <div className="h2 text-bold text-primary">Estamos procesando la solicitud...</div>
              <div className="h5">Generando y enviando certificados a los correos de los participantes.</div>
              <div className="h5">Este proceso puede demorar unos minutos dependiendo de la cantidad de participantes.</div> 
              
              <div className="fa fa-paper-plane display-5 mt-5"></div>
              <div className="fa fa-paper-plane display-1 mt-5"></div>
              <div className="fa fa-paper-plane display-4 mt-5"></div>
              <div className="fa fa-paper-plane display-5 mt-5"></div>
              <div className="text-center m-5">
                <div className="spinner-border mt-3 mb-4" role="status"></div>
                <p className="h5">Procesando solicitud aguarde por favor... </p>
              </div>           
            </>
            ):(
              <>
              <div className="row justify-content-center">
                <div className="col-md-4 h4 text-bold  mb-3 mt-2 alert alert-primary">
                  <span className="fa fa-check mr-2"></span>
                  Finalizado!
                </div>

              </div>
                <div className="h4 text-info mt-5">Se ha generado y enviado los certificados a los correos de los participantes de la solicitud "{solicitudActual.titulo}" </div>
                {/* <h5>A continuación se encuentra el detalle de los certificados enviados:</h5>   */}
                <div className="text-bold mt-5">Esta solicitud ya no aparecera en la pestaña de "Pendientes de emisión" se la movio a la pestaña "Emitidos". </div>
              </>)
            }
          </div>
      </div>
    </div>
    
)
}
 
export default TabSolicitudesPendientes;