const optionsLiteral = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const optionsCorto = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
const FechaFormato = (fecha, tipo) => {
  let options=[];
  switch(tipo){
    case "literal":
      options = optionsLiteral;
    break;
    default:
    case "corto":
      options = optionsCorto;
    break;
  }
  const fechaFactura= new Date(fecha).toLocaleDateString('es-ES', options);

  return fechaFactura;
}
 
export default FechaFormato;