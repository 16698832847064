const GridPersonasSeleccionar = ({
  modo="agregar",
  dataPersonas,
  gridAccion,
  onSelectAccion = null,
}) => {
  if (dataPersonas)
    return (
          <div className="table-responsive">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Persona</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataPersonas.map((item) => (
                  <tr key={item.id} className={"row-button"}>
                    <td>{item.id} </td>
                    <td>
                      <strong>{item.nombre_completo}</strong>
                      <br/>
                      <small>

                        <span className="text-secondary">
                              {item.tipo_rol_valor}
                        </span>
                          <span className="text-secondary">
                            <strong>  </strong> - {item.ci}
                          </span>
                      </small>
                      
                    </td>
                    <td>
                      <div className="row">
                        <div className="col-md-6">
                          {modo==="agregar" && !item.agregado &&
                            <button
                              onClick={() => onSelectAccion(item)}
                              className="btn btn-sm btn-success"
                            >
                              <span className="fa fa-arrow-right"></span><br/> Elegir
                            </button>                          
                          }
                          {modo==="agregar" && item.agregado &&
                            <p className="text-info"><sub>En la lista</sub></p>                       
                          }
                          {modo==="quitar" &&
                            <button
                              onClick={() => onSelectAccion(item)}
                              className="btn btn-sm btn-danger"
                            >
                              <span className="fa fa-arrow-left"></span><br/> Quitar
                            </button>    
                          }
                        </div>
                        {modo==="agregar" && 
                          <div className="col-md-5">
                            <button
                              onClick={() => gridAccion(item)}
                              className="btn btn-lg "
                            >
                              <span className="fa fa-bars"></span>
                            </button>
                          </div>                        
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
    );
};

export default GridPersonasSeleccionar;
