import { Link } from "react-router-dom";

const Page403 = () => {
  return (
    <div className="container bg-light">
      <div className="row p-4">
        <section className="col-md-12">
          <div className="error-page">
            <h2 className="headline text-danger"> 403</h2>

            <div className="error-content">
              <h3>
                <i className="fas fa-exclamation-triangle text-warning"></i> No
                cuenta con permisos para ver esta sección
              </h3>

              <p>
                No tiene permisos para acceder a esta sección. Puede intentar
                <Link to="/home"> volver al inicio</Link> o preguntar al
                administrador del sistema.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Page403;
