import React, {  useState } from 'react';

const SearchBarSolicitud = ({setBusqueda}) => {
  const [nombre, setNombre] = useState("");

  let minPermitido=3;

  const handleBuscar = ()=>{
    let salida = {
      nombre
    }
    setBusqueda(salida);
  }
  const handleBorrar = ()=>{
    setNombre("");
    let salida = {
      nombre:""
    }
    setBusqueda(salida);
  }

  let btnActivado = true;

  if(nombre.length < minPermitido && nombre.length > 0){
    btnActivado=false;
  }
  let nombreEstilo={};
  if(nombre.length < minPermitido && nombre.length > 0){
    nombreEstilo = {outline:"red 1px solid"};
  }
  return ( 
    <div className="row align-items-end">
      <div className="col-md-6">
        <label className="col-form-label">Título:</label>
        <input
          className="form-control"
          type="text"
          //ref={nombre}
          onChange={(e)=>setNombre(e.target.value)}
          style={nombreEstilo}
          value={nombre}
        />
      </div>
      <div className="col-md-2">
        <div className="btn-group">

          <button className='btn btn-default' onClick={handleBorrar} >Borrar</button>
          <button className="btn btn-primary" onClick={handleBuscar} disabled={btnActivado ? null : "disabled"}>
            <i className="fa fa-search"></i> Buscar
          </button>
        </div>

      </div>
    </div>
   );
}
 
export default SearchBarSolicitud;