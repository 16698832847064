import { useState, useRef, useEffect } from 'react';
import plantilla_estudiante_low from "../../assets/plantilla_estudiante_prev.jpg";
import plantilla_docente_low from "../../assets/plantilla_docente_prev.jpg";
import Modal from '../UI/Modal';
import useAxios, { configure } from "axios-hooks";
import axiosClient from '../../api/axiosClient';
import Notification from '../UI/Notification';

configure({ axios: axiosClient });

const URL_PREVIA = "/certificados/solicitud/vistaprevia";

const GridEmision = ({ solicitud, setAprobados}) => {
  
  const [personas, setPersonas] = useState(solicitud.personas);
  
  const handleAprobar=(id_persona, estado)=>{
    setPersonas(anterior =>{
      let nuevo = [...anterior];
      nuevo.forEach(per =>{
        if(per.id === id_persona) per.aprobado = estado;
      });
      return nuevo;
    });
    
  }
  const handleAprobarTodos = ()=>{
    setPersonas(anterior =>{
      let nuevo = [...anterior];
      nuevo.forEach(per =>{
        per.aprobado = true;
      });
      return nuevo;
    });
  }
  const FormularioRow = (label, component) => {
    // return <dl className="row mb-1 mb-1 pt-2 ">
    //   <dt className="col-sm-5">{label}</dt>
    //   <dd className="col-sm-7 ">{component}</dd>
    // </dl>;
    return (
      <>
        <dt className="col-md-5 col-sm-12">{label}</dt>
        <dd className="col-md-7 col-sm-12 ">{component}</dd>
      </>
    );
  };

  const modal = useRef();
  const handleModalVistaPrevia =(e, id_solicitud, id_persona)=>{
    e.preventDefault();
    modal.current.Abrir();
    //setPersonaActual(id_persona);
    
    
    fetchPrevia({params:{
      id_solicitud,
      id_persona,
    }}).then(null, error=>{
      Notification(
        "alert",
        typeof error === "object"? error.response.data.message :"Se encontraron errores:\n" + error,
        "Error"
      );
    });;
  }
  const handleCerrarModal = ()=>{
    modal.current.Cerrar();
  }
  const [{ data, loading, error }, fetchPrevia] = useAxios(
    {
      url:  URL_PREVIA,
      method: "GET",
      params: {
        id_solicitud:solicitud.id,
        responseType: 'arraybuffer'
            
      }
    },
    { manual: true }
  );

  useEffect(()=>{
    //verificar si todos estan aprobados
    let aprobado=true;
    personas.forEach(per => {
      if(!per.aprobado) aprobado=false;
    });
    setAprobados(aprobado);   
  },[personas]);

  return (
    <div>
      <p className='text-right'>
        <button className='btn btn-sm btn-outline-info mr-5' onClick={handleAprobarTodos}>
          <span className='fa fa-check text-lg'></span>
          <span className='fa fa-check text-xs mr-2 '></span>
          Aprobar Todos</button>
      </p>
      {personas.map((persona) => (
        <div key={persona.id} className={persona.aprobado?"card bg-info":"card bg-gray"}>
          <div className="card-header ">
            <div className="row">
            <div className="col-md-4">
              No. Identificación: {persona.ci}
            </div>
              <div className="col-md-4">
                {!persona.aprobado &&
                  <div className='text-center text-warning h5'>
                    <span className="fa fa-clock mr-2"></span>
                    Pendiente de aprobación</div> 
                }
   
              </div>
              <div className="col-md-4"><div className="text-right">
                {!persona.aprobado &&
                  <button className="btn btn-info " onClick={()=>handleAprobar(persona.id, true)}>
                    <span className='fa fa-check mr-2'></span>
                    Aprobar</button>                  
                }
                {persona.aprobado &&
                  <button className="btn btn-warning" onClick={()=>handleAprobar(persona.id, false)}>
                  <span className='fa fa-check-circle mr-2'></span>
                    Aprobado</button>
                }
            </div></div>
            </div>
            
            
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-8 ">
                <dl className="row mb-1  pt-2 ">
                  {FormularioRow("Rol:", persona.tipo_rol_valor)}
                  {FormularioRow("Nombre participante:", persona.nombre_completo)}
                  {/* {FormularioRow("Documento de identidad:", persona.ci)} */}
                  {FormularioRow("Género:", persona.genero)}
                  {FormularioRow("Correo electrónico:", persona.email)}
                  {FormularioRow("Carrera:", persona.carrera)}
                </dl>
              </div>
              {/* <div className="col-md-5">
                <dl className="row mb-1 pt-2 ">
                  
                </dl>
              </div> */}
              <div className="col-md-3">
                <a href="/#" onClick={(e)=>handleModalVistaPrevia(e,solicitud.id, persona.id)}>
                  <img 
                  src={persona.tipo_persona === "1" ?plantilla_estudiante_low: plantilla_docente_low} 
                  alt="Vista previa" 
                  className='img-fluid' />              

                </a> 
              </div>
            </div>
          </div>
        </div>
      ))}
      <Modal ref={modal} 
        titulo="Vista previa del certificado" 
        loading={false} 
        buttons={<button onClick={handleCerrarModal} className='btn btn-secondary'>Cerrar</button>} 
        size={3} 
      >
        <div style={{minHeight:"400px"}} className='bg-dark text-center p-1'>
          {loading && 
            <div className="text-center m-5">
              <div className="spinner-border mt-5 mb-4" role="status"></div>
              <p className="text-secondary h5">Generando vista previa... </p>
            </div> 
          }
          {!loading &&
          // <img style={{width:"100%"}}  src={data}  alt="vista previa" />          
             <embed 
              type="application/pdf"
              src={data}
              width="100%"
              height="650px"
            /> 
          }
        </div>
         
      </Modal> 
      <p className='text-right mr-4'>
        <button className='btn btn-sm btn-outline-info' onClick={handleAprobarTodos}>
        <span className='fa fa-check text-lg'></span>
        <span className='fa fa-check text-xs mr-2 '></span>
            Aprobar Todos</button>
      </p>
    </div>
  );
};

export default GridEmision;
