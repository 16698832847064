import SelectRol from "../UI/SelectRol";
import {PARAMETROS} from "../userdata/Parametros";
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import FormularioRow, { FrLabelValue } from "../Layout/FormularioRow";

configure({ axios: axiosClient });

let nuevaUniversidad={
  id:0,
  nombre:"",
  pais:"",
}
const URL_GUARDAR_UNI= "/certificados/universidad/guardar";
const URL_ACTUALIZAR_UNI= "/certificados/universidad/actualizar";

const FormUniversidad = forwardRef(({showButtons=false, editar=false}, ref) => {
  
  const [universidad, setUniversidad] = useState({...nuevaUniversidad});
  const [URL_API, setURL_API] = useState(URL_GUARDAR_UNI);
  
  useImperativeHandle(ref, ()=>{
    return {
      Aceptar(){
        return Guardar();
      },
      Cancelar(){
        setURL_API(URL_GUARDAR_UNI);
        Reset();
      },
      SetData(data){
        setURL_API(URL_ACTUALIZAR_UNI);
        setUniversidad(data);
      }
    }
  },[universidad])

  const handleUnivesidad=(parametro, e)=>{
    let valor = e.target.value;
    //nuevaUniversidad[parametro] = valor
    setUniversidad(prev =>{
      let nuevo = {...prev};
      nuevo[parametro] = valor;
      return nuevo;
    });

  }

  const [{ data, loading, error }, fetchGuardar] = useAxios(
    {
      url:  URL_API,
      method: "POST",
      params: {
        id:universidad.id,
        nombre: universidad.nombre,
        pais: universidad.pais,
      }
    },
    { manual: true }
  );

  const Guardar = ()=>{
    let valido=true;
    let message ="";

    return new Promise((resolve, reject)=>{

      if(universidad.nombre === ""){
        message+="falta nombre\n";
        valido=false;
      }
      if(universidad.pais === ""){
        message+="falta pais\n";
        valido=false;
      }
      if(valido){
        resolve(valido)
        
      }else{
        reject(message);
      }
    }).then(response =>{
      return fetchGuardar();
    })

  }
  const Reset=()=>{
    setUniversidad({...nuevaUniversidad});
  }

  if(PARAMETROS)
    return ( 
      <div className="container">
         {
          editar &&
          <FormularioRow >
            <FrLabelValue
              label={"Nombre universidad:"}
              component={<input onChange={e=>handleUnivesidad("nombre",e)} className="form-control" type="text" value={universidad.nombre}  />}
            />
            <FrLabelValue
              label={"País:"}
              component={<input onChange={e=>handleUnivesidad("pais",e)} className="form-control" type="text" value={universidad.pais}  />}
            />
          </FormularioRow>
         } 
        {
          !editar &&
          <FormularioRow>
            <FrLabelValue
              label={"Nombre completo:"}
              component={universidad.nombre}
            />
            <FrLabelValue
              label={"Genero:"}
              component={universidad.pais}
            />
            <FrLabelValue
              label={"Creado por:"}
              component={<>{universidad.creador}<br/>{universidad.created_at}</>}
            />
            <FrLabelValue
              label={"Modificado por:"}
              component={<>{universidad.modificador}<br/>{universidad.updated_at}</>}
            />
          </FormularioRow>
        }
        {showButtons && 
          <>
            <button className="btn btn-default"  onClick={Reset} >Reset</button>
            <button className="btn btn-primary" onClick={Guardar} >Guardar</button>
          </>
        }
      </div>
    );
})
 
export default FormUniversidad;