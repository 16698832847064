import FechaFormato from "../util/FechaFormato";

const GridEmitidos = ({ data, gridAccion, gridEmision =null}) => {
  return (
    <div className="table-responsive">
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Solicitud No.</th>
            <th scope="col">Fecha emisión</th>
            <th scope="col">Sede</th>
            <th scope="col">Titulo del certificado</th>
            <th scope="col">Metodologia</th>
            <th scope="col">Universidad Par</th>
            <th scope="col">Horas academicas</th>
            <th scope="col">Personas asignadas</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id} </td>
              <td>{FechaFormato(item.fecha_emision, "corto")} </td>
              <td>{item.sede_valor} </td>
              <td>{item.titulo} </td>
              <td>{item.metodologia_valor} </td>
              <td>{item.universidad_valor} </td>
              <td>{item.horas} </td>
              {/* <td>{item.no_personas} </td> */}
              <td>{item.personas.length} </td>
              
              <td>
                <div className="row">
                  <div className="col-md-5 p-0">
                    <button
                      onClick={() => gridAccion(item)}
                      className="btn btn-sm btn-warning"
                    >
                      <span className="fa fa-eye"></span> Detalle
                    </button>
                  </div>
                  {gridEmision &&
                    <div className="col-md-5 p-0">
                      <button
                        onClick={() => gridEmision(item)}
                        // className="btn btn-square btn-outline-primary"
                        className="btn btn-app bg-primary"
                      >
                        <span className="fa fa-mail-bulk"></span> Emitir
                      </button>
                    </div>
                  }
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GridEmitidos;
