import { useRef, forwardRef, useImperativeHandle, useId, useEffect } from "react";
import { createPortal } from "react-dom";

let modalRoot = document.getElementById("modal_section");
const Modal = forwardRef(({ handleDialogoClose=null,titulo, children, loading=false, loadingText="Guardando...", buttons=null, size=2}, ref) => {
  
  const modal = useRef();
  //const clase = size !==1 ? (size===2? "dialog col-md-8":"dialog col-md-11") :"dialog col-md-6";
  const clase ="modal-dialog " + (size !==1 ? (size===2? "modal-lg":"modal-xl") :"modal-sm");
  const handleCerrar= ()=>{
    //handleDialogoClose();
    //modal.current.close();
    window.jquery("#"+CSS.escape("modal_"+modalId)).modal("hide");
  }
  if(handleDialogoClose==null){
    handleDialogoClose=handleCerrar;
  }
  const modalId = useId();
  useImperativeHandle(ref, ()=>{
    return {
      Abrir(){
        //modal.current.showModal();
        window.jquery("#"+CSS.escape("modal_"+modalId)).modal("show");
      },
      Cerrar(){
        handleCerrar();
      }
    }
  },[]);
useEffect(()=>{

},[])
  return createPortal( 
    <>
      <div className="modal" tabIndex="-1" ref={modal} id ={'modal_'+modalId} aria-labelledby={'modal_'+modalId} data-keyboard="false" data-backdrop="static">
        <div className={clase}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5 className="modal-title">{titulo}</h5>
              <button type="button" className="close" data-dismiss="modal_" aria-label="Close" onClick={handleDialogoClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loading &&<div className="capaCarga"></div> }
              {children}  
            </div>
            <div className="modal-footer bg-light">
              {loading &&
              <div className="d-flex justify-content-center">
              <strong>{loadingText} </strong>
              <div className="spinner-border ms-auto ml-2" role="status" aria-hidden="true"></div>
            </div>
              }
              {!loading && buttons }
            </div>
          </div>
        </div>
      </div>
    </>
   ,
   modalRoot);
});
 
export default Modal;