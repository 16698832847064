import './App.css';

import AppRouter from "./components/router/AppRouter";

function App() {
  //change for redux instead of states
   

  return (
    //<AxiosInterceptor >      
    //<ChakraProvider>
      <AppRouter />

    //</ChakraProvider>
    //</AxiosInterceptor>
    
    // <div className="App">
    //   <Signin />
    //   <CulminacionEstudios />      
    //   <Mencion />
    //   <Diploma />
    // </div>
    
  );
}

export default App;
