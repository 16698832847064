const SelectRol = ({ data, onChange, first = null, ...props }) => {
  return (
    <select onChange={(e) => onChange(e)} className="form-control" {...props}>
      {first && <option value={first.codigo}> {first.valor}</option>}
      {data.map((d) => (
        <option key={d.codigo} value={d.codigo}>
          {" "}
          {d.valor}
        </option>
      ))}
    </select>
  );
};

export default SelectRol;
