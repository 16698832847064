import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import { useNavigate } from "react-router-dom";
import logo from "../../img/logo.png";

configure({ axios: axiosClient });
const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Signin = ({ fetch: comprobarUsuario }) => {
  const [autenticando, setAutenticando] = useState(false);
  const [messageError, setMessageError] = useState(null);

  const navigate = useNavigate();

  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: "/user/authuser",
      method: "POST",
    },
    { manual: true }
  );

  const handleCallbackResponse = async (response) => {
    //console.log("Exonded JWT Id token " + response.credential);
    // var userObject = jwtDecode(response.credential);
    // console.log(userObject);
    // setUser(userObject);
    setAutenticando(true);

    fetch({ params: { idToken: response.credential } })
      .then(() => {
        console.log("Acceso correcto, redireccionando");
        comprobarUsuario().then(() => {
          navigate("/home");
        });
      })
      .catch((error) => {
        setAutenticando(false);
        //console.log(error.toJSON());
        if (typeof error.response !== "undefined") {
          if (
            typeof error.response.data.message !== "undefined" &&
            error.response.status === 401
          ) {
            setMessageError(error.response.data.message);
          }
        } else {
          setMessageError(error.message);
        }
      });
    //manejar error de autenticacion aqui

    //fetchData(response.credential);
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: google_client_id,
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "filled_blue",
      size: "large",
      shape: "pill",
    });
    google.accounts.id.prompt();
  }, []);

  return (
    // <div className='container'>
    //     <div className='row align-items-center justify-content-center'>
    //         <div className="col-md-8 col-sm-12 text-center">

    //         </div>

    //     </div>

    // </div>
    <div className="login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <span className="h3">Sistema de emisión de certificados RRII</span>
          </div>
          <div className="card-body login-card-body">
            <img src={logo} alt="logo" className="img-fluid mx-auto d-block" />
            <p className="login-box-msg fw-bold">
              Bienvenid@ inicie sesión con su cuenta institucional de Google
            </p>
            <div
              style={{ margin: "10px auto", width:"fit-content" }}
              id="signInDiv"
              hidden={autenticando}
            ></div>

            {autenticando && (
              <div>
                <div className="login-box-msg">
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
                <p className="login-box-msg">Autenticando usuario...</p>
              </div>
            )}
            {messageError && (
              <p className="login-box-msg alert alert-danger mt-2">
                Ocurrio un error: <br />
                {messageError}
              </p>
            )}
            <p className="login-box-msg">
              Universidad Privada Franz Tamayo <br /> 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
