import React, {  useState } from 'react';

const SearchBarUniversidad = ({setBusqueda}) => {
  const [nombre, setNombre] = useState("");
  const [pais, setPais] = useState("");

  let minPermitido=3;

  const handleBuscar = ()=>{
    let salida = {
      nombre,
      pais ,
    }
    setBusqueda(salida);
  }
  const handleBorrar = ()=>{
    setNombre("");
    setPais("");
    let salida = {
      nombre:"",
      pais:"" ,
    }
    setBusqueda(salida);
  }

  let btnActivado = true;

  if((nombre.length < minPermitido && nombre.length > 0) || (pais.length < minPermitido && pais.length > 0)){
    btnActivado=false;
  }
  let nombreEstilo={};
  if(nombre.length < minPermitido && nombre.length > 0){
    nombreEstilo = {outline:"red 1px solid"};
  }
  let paisEstilo={};
  if(pais.length < minPermitido && pais.length > 0){
    paisEstilo = {outline:"red 1px solid"};
  }
  return ( 
    <div className="row align-items-end">
      <div className="col-md-6">
        <label className="col-form-label">Nombre:</label>
        <input
          className="form-control"
          type="text"
          //ref={nombre}
          onChange={(e)=>setNombre(e.target.value)}
          style={nombreEstilo}
          value={nombre}
        />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">País:</label>
        <input
          className="form-control"
          type="text"
          //ref={ci}
          onChange={(e)=>setPais(e.target.value)}
          style={paisEstilo}
          value={pais}
        />
      </div>
      <div className="col-md-2">
        <div className="btn-group">

          <button className='btn btn-default' onClick={handleBorrar} >Borrar</button>
          <button className="btn btn-primary" onClick={handleBuscar} disabled={btnActivado ? null : "disabled"}>
            <i className="fa fa-search"></i> Buscar
          </button>
        </div>

      </div>
    </div>

   );
}
 
export default SearchBarUniversidad;