import SelectRol from "./SelectRol";
// import useAxios, { configure } from "axios-hooks";
// import axiosClient from "../../api/axiosClient";
import {PARAMETROS} from "../userdata/Parametros";

//configure({ axios: axiosClient });

let salida = {
  rol:0,
  sede:"",
}

const FilterBar = ({setFiltro, filtroSede=null}) => {
  // var [{ data, loading, error }, fetch] = useAxios(
  //   {
  //     url: "/certificados/parametros",
  //     method: "GET",
  //   }
  // );
  const handleChange = (e, componente)=>{
    //console.log(e.target.value, componente);
    salida[componente] = e.target.value;
    setFiltro(salida);
  }
  //var data = Parametros();

  if(PARAMETROS){
    /*data.TIPO_ROL.unshift({
      tipo: "TIPO_ROL",
      codigo: "0",
      valor: "-- TODOS -- "
   });*/
    return (     
      <div className="row align-items-end">
        {
          // filtroSede && 
        <div className="col-md-2">
          <label>Sede:</label> 
          <SelectRol key={filtroSede} onChange={(e)=>handleChange(e,"sede")} data={PARAMETROS.SEDE} first={{codigo:"", valor:"- Todos -"}} defaultValue={filtroSede} />                 
        </div>
        }
        <div className="col-md-4">
          <label>Rol de la persona:</label> 
          <SelectRol onChange={(e)=>handleChange(e,"rol")} titulo={"Rol de la persona:"} data={PARAMETROS.TIPO_ROL} first={{codigo:0, valor:" - Todos -"}} />     
        </div>
      </div> 
    );
  }
}
 
export default FilterBar;