import { Route, Routes, useLocation } from "react-router-dom";
import React, { useState } from 'react';
import Navbar from "../Layout/Navbar";
import HomePage from "../paginas/HomePage";
import Signin from "../userdata/Signin";
import ProtectedRoute from "../util/ProtectedRoute";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import Page404 from "../paginas/Page404";

import Page403 from "../paginas/Page403";

import LoadOverLay from "../Layout/LoadOverlay";

import PageSolicitudes from "../paginas/PageSolicitudes";
import PagePersonas from "../paginas/PagePersonas";
import GetParametros from "../userdata/Parametros";
import PageUniversidades from "../paginas/PageUniversidades";

configure({ axios: axiosClient });

const AppRouter = () => {
  //GetParametros();
  
  const location = useLocation();
  const [autorizado, setAutorizado] = useState(true);

  var [{ data, loading, error }, fetch] = useAxios(
    {
      url: "/user/info",
      method: "GET",
    },
    {
      manual: location.pathname === "/signin" || location.pathname === "/",
    }
  );
  if (error) {
    console.log("error de autenticacion");
  }

  window.user = "";
  if (data) {
    
    GetParametros();
    //console.log(data);
    window.user = data;
    //funcion para obtener los permisos
    window.can = (permiso) => {
      var index = window.user.permissions.findIndex(
        //(item) => item.name === permiso
        (item) => permiso.split("|").indexOf(item.name) >= 0
      );
      return index >= 0;
    };
    

  }

  if (!loading)
    return (
      <Routes>
        <Route path="signin" element={<Signin fetch={fetch} />} />
        <Route
          element={
            <ProtectedRoute
              acceso={data && autorizado}
              redirectPath={"/signin"}
            />
          }
        >
          <Route
            element={
              <Navbar dataUsuario={data} handleEndSession={setAutorizado} />
            }
          >
            <Route element={<ProtectedRoute acceso={data && window.can("admin|mod_persona")} />} >
              <Route path="personas" element={<PagePersonas />} />
            </Route>
            <Route element={<ProtectedRoute acceso={data && window.can("admin|mod_universidad")} />} >
              <Route path="universidades" element={<PageUniversidades />} />
            </Route>
            <Route element={<ProtectedRoute acceso={data && window.can("admin|mod_solicitud")} />} >
              <Route path="solicitudes" element={<PageSolicitudes />} />
            </Route>
            <Route element={<ProtectedRoute acceso={data && window.can("admin|mod_emision")} />} >
              <Route path="emision" element={<PageSolicitudes />} />
            </Route>            

            <Route path="home" element={<HomePage data={data} />} />
            
            <Route path="/Page403" element={<Page403 />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Route>
      </Routes>
    );
  if (loading) {
    return <LoadOverLay mensaje={"Cargando sistema...."} />;
  }
};

export default AppRouter;
