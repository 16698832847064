import Pagination2 from "../Layout/Pagination2";
import GridEmitidos from "../forms/GridEmitidos";
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import Modal from "../UI/Modal";
import SearchBarSolicitud from "../UI/SearchBarSolicitud";
import FilterBarSolicitud from "../UI/FilterBarSolicitud";
import DetalleEmitidos from "./DetalleEmitidos";

configure({ axios: axiosClient });
const URL_SOLICITUDES = "/certificados/solicitudes/emitidas";

const TabSolicitudesEmitidas = forwardRef(({props},ref) => {

  const modal = useRef();
  useImperativeHandle(ref,()=>{
    return {
      Actualizar(){
        fetchLista({
          params: filtro,
        });
      }
    }
  },[])
  const [solicitudActual, setSolicitudActual] = useState();
  const [filtro, setFiltro] = useState({
    page_number: 1,
    page_size: 10,
    sede: "",
    metodologia: 0,
    titulo: "",
  });
  const [{ data, loading, error }, fetchLista] = useAxios(
    {
      url: URL_SOLICITUDES,
      method: "GET",
    },
    { useCache: false, manual: true }
  );

  const handleSetPage = (page) => {
    //setCurrentPage(page);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = page;
      return nuevo;
    });
  };
  const handleFiltro = (pfiltro) => {
    console.log(pfiltro);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.sede = pfiltro.sede;
      nuevo.metodologia = pfiltro.metodologia;
      return nuevo;
    });
  };

  const handleBusqueda = (busqueda) => {
    //console.log(busqueda);
    setFiltro((prev) => {
      const nuevo = { ...prev };
      nuevo.page_number = 1;
      nuevo.titulo = busqueda.nombre;
      return nuevo;
    });
  };
  const handleDialogoCancelar=()=>{
    modal.current.Cerrar();
  }
  const buttonsDetallePersona = <><button
  onClick={handleDialogoCancelar}
  className="btn btn-secondary"
>
  Cerrar
</button></>;

const [buttons, setButtons] = useState(buttonsDetallePersona);

const handleGridAccion =(solicitud)=>{   

  // setButtons(buttonsDetallePersona);
  // formSolicitud.current.SetData(solicitud);
  setSolicitudActual(solicitud);
  modal.current.Abrir();
}

useEffect(() => {
  fetchLista({
    params: filtro,
  }).catch(() => {
    //console.log(error);
    console.log("ocurrio un error");
  });
}, [filtro]);

  return (    
  <>
  <div className="h5">Solicitudes emitidas</div>
    <p>      
      <a
        className="btn btn-primary"
        data-toggle="collapse"
        href="#collapseFilterEmitidas"
        role="button"
        aria-expanded="false"
        aria-controls="collapseFilterEmitidas"
      >
        Buscar y filtrar <i className="fa fa-solid fa-filter"></i>
      </a>
    </p>
    <div className="collapse" id="collapseFilterEmitidas">
      <div className="card card-body">
        <SearchBarSolicitud setBusqueda={handleBusqueda} />
        <FilterBarSolicitud setFiltro={handleFiltro} />
      </div>
    </div>

    {!loading && data ? (
      <>
        <GridEmitidos data={data.lista} gridAccion={handleGridAccion} />
        <Pagination2
          nPages={Math.ceil(data.size / filtro.page_size)}
          currentPage={filtro.page_number}
          setCurrentPage={handleSetPage}
        />
      </>
    ) : (
      <div className="text-center m-5">
        <div className="spinner-border mt-5" role="status"></div>
        <p>Cargando lista... </p>
      </div>
    )}
      <Modal handleDialogoClose={handleDialogoCancelar} titulo={"Datos solicitud"} ref={modal} buttons={buttons} size={3}>
        {/* <FormSolicitud ref={formSolicitud} editar={false}   /> */}
        {solicitudActual && 
          <DetalleEmitidos solicitud ={solicitudActual} />
        }
      </Modal>
</>
);
})
 
export default TabSolicitudesEmitidas;