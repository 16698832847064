
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import { useRef, useState, forwardRef, useImperativeHandle } from "react";
import Notification from '../UI/Notification';

configure({ axios: axiosClient });

const URL_LISTA_INTERNOS= "/certificados/personas/buscar_interno";

const EstudianteInterno = forwardRef(({param, onEstudianteSelect}, ref) => {
  const ci = useRef();
  const nomape=useRef();

  useImperativeHandle(ref,()=>{
    return {
      Reset(){
        ci.current.value="";
        nomape.current.value="";
        setVerGrilla(false);
      }
    }
  },[])
  const [inputValidation, setinputValidation] = useState({ci:true, nomape: true});
  const [verGrilla, setVerGrilla] = useState(true);
  const [{ data, loading, error }, fetchInternos] = useAxios(
    {
      url:  URL_LISTA_INTERNOS,
      method: "GET"
    },
    { manual: true }
  );
  const handleBuscar = ()=>{
    setinputValidation({ci:true, nomape: true});
    let ciValorLength = ci.current.value.trim().length;
    let nomapeValorLength = nomape.current.value.trim().length;
    
    if(ciValorLength ===0 && nomapeValorLength ===0)   
      {
       setinputValidation({ci:false, nomape: false});
       alert("Debe llenar por lo menos uno de los campos");
       return;
      }
    if((ciValorLength > 0 && ciValorLength < 4) && (nomapeValorLength >0 && nomapeValorLength<4))   
      {
        setinputValidation({ci:false, nomape: false});
        alert("Debe llenar por lo menos uno de los campos con un minimo de 4 letras");
        return;
      }
   if(ciValorLength ===0 && nomapeValorLength<4){
    setinputValidation({ci:true, nomape: false});
    alert("Debe llenar el nombre por lo menos con un minimo de 4 letras");
    return;
   }
   if(nomapeValorLength ===0 && ciValorLength<4){
    setinputValidation({ci:false, nomape: true});
    alert("Debe llenar el carnet por lo menos con un minimo de 4 letras");
    return;
   }
   
    fetchInternos({params:{
      sede: param.codigo,
      ci: ci.current.value,
      nombre_completo: nomape.current.value,
    }}).then(res=> setVerGrilla(true)
      , error=>{
      Notification(
        "alert",
        typeof error === "object"? error.response.data.message :"Se encontraron errores:\n" + error,
        "Error"
      );
    });
  }
  const handleElegir = (estudiante)=>{
    onEstudianteSelect(estudiante);
  }
  return ( 
    <>
      <p className="">Buscar estudiante interno <strong>(Unifranz)</strong> por carnet de identidad o nombre y apellidos en la sede <strong>{param.valor}</strong> .</p>
      <div className="row">
        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="">Carnet de identidad</label>
            <input type="text" className={inputValidation.ci? "form-control":"form-control is-invalid"} placeholder="Carnet de identidad" ref={ci} />
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="">Nombres y/o apellidos</label>
            <input type="text" className={inputValidation.nomape? "form-control":"form-control is-invalid"} placeholder="Nombres y/o apellidos" ref={nomape} />
          </div>
        </div>
        <div className="col-md-2 text-left align-self-end">
          <button className="btn btn-primary mb-3" onClick={handleBuscar} disabled={loading?"disabled":null} >Buscar</button>
        </div>
      </div>
    {!data && 
    <p className="text-center text-info m-5">Escriba un carnet de identidad o el nombre del estudiante</p>
    }
    {loading && 
          <div className="text-center m-5">
            <div className="spinner-border mt-5 mb-4" role="status"></div>
            <p className="text-secondary">Buscando... </p>
          </div> 
        }
    {data && !loading && verGrilla &&
    <>
      {data.length === 0 ? 
      <div className="text-center text-danger m-5">No se encontraron resultados</div> :
      <>
      <div className="m-1">Mostrando 10 primeros resultados</div>
        <div className="table-responsive">
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Ci</th>
                <th scope="col">Nombre completo</th>
                {/* <th scope="col">Correo electrónico</th> */}
                <th scope="col">Carrera</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.ci}>
                  <td>{item.ci} </td>
                  <td>{item.nombre_completo} </td>
                  {/* <td>{item.correo} </td> */}
                  <td>{item.carrera} </td>
                  <td>
                    <button
                      onClick={() => handleElegir(item)}
                      className="btn btn-sm btn-success"
                    >
                      <span className="fa fa-arrow-right"></span><br/> Elegir
                    </button>   
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
      </>      
      }
    </>
    }
    </>
   );
})
 
export default EstudianteInterno;