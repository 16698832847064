import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
configure({ axios: axiosClient });
const global_url_gateway = process.env.REACT_APP_URL;

export let PARAMETROS = null;

const GetParametros = async () => {
  if(PARAMETROS ==null){
    //PARAMETROS={};
    const response = await fetch(global_url_gateway+"/certificados/parametros", 
    { 
      method: "GET",
       credentials: "include",
       cache: 'reload',
       headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      PARAMETROS = await response.json();
  }
};
//ya no usar un HOOK

export default GetParametros;
