const FormularioRow = ({ children }) => {
  return <dl className="row">{children}</dl>;
};

export const FrLabelValue = ({ label, component }) => {
  return (
    <>
      <dt className="col-sm-5 form-row">{label}</dt>
      <dd className="col-sm-7 form-row">{component}</dd>
    </>
  );
};

export default FormularioRow;