import SelectRol from "../UI/SelectRol";
import {PARAMETROS} from "../userdata/Parametros";
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import useAxios, { configure } from "axios-hooks";
import axiosClient from "../../api/axiosClient";
import FormularioRow, { FrLabelValue } from "../Layout/FormularioRow";
import Modal from "../UI/Modal";
import EstudianteInterno from "./EstudianteInterno";

configure({ axios: axiosClient });

let nuevaPersona={
  id:0,
  sede:"0",
  tipo_rol:"0",
  genero:"",
  ci:"",
  nombre_completo:"",
  email:"",
  carrera:""
}
const URL_GUARDAR_PERSONA= "/certificados/personas/guardar";
const URL_ACTUALIZAR_PERSONA= "/certificados/personas/actualizar";

const FormPersona = forwardRef(({showButtons=false, editar=false}, ref) => {
  
  const [persona, setPersona] = useState({...nuevaPersona});
  const [URL_API, setURL_API] = useState(URL_GUARDAR_PERSONA);
  const [btnInterno, setBtnInterno] = useState(false);
  const modal = useRef();
  const estInternoRef = useRef();
  
  useImperativeHandle(ref, ()=>{
    return {
      Aceptar(){
        return Guardar();
      },
      Cancelar(){
        setURL_API(URL_GUARDAR_PERSONA);
        Reset();
      },
      SetData(data){
        setURL_API(URL_ACTUALIZAR_PERSONA);
        setPersona(data);
        setBtnInterno(data.tipo_rol==="1");
      }
    }
  },[persona])

  const handlePersona=(parametro, e)=>{
    let valor = e.target.value;
    //nuevaPersona[parametro] = valor
    setPersona(prev =>{
      let nuevo = {...prev};
      nuevo[parametro] = valor;
      //if(parametro==="sede") nuevo["ci"]="";
      return nuevo;
    });
    //boton estudiante interno
    //setBtnInterno(parametro === "tipo_rol" && valor ==="1");
  }
  const handleSelectRol =(e)=>{
    let valor = e.target.value;
    setPersona(prev =>{
      let nuevo = {...prev};
      nuevo["tipo_rol"] = valor;
      if(valor ==="1"){
        nuevo["ci"] ="";
        nuevo["carrera"] = "";
      }
      return nuevo;
    });
    setBtnInterno(valor === "1");
    
  }

  const [{ data, loading, error }, fetchGuardar] = useAxios(
    {
      url:  URL_API,
      method: "POST",
      params: {
        id:persona.id,
        //tipo_persona: 1,
        tipo_rol: persona.tipo_rol,
        ci: persona.ci,
        nombre_completo: persona.nombre_completo,
        email: persona.email,
        genero: persona.genero,
        sede: persona.sede,
        carrera: persona.carrera
      }
    },
    { manual: true }
  );

  const Guardar = ()=>{
    let valido=true;
    let message ="";

    return new Promise((resolve, reject)=>{

      if(persona.sede === "0"){
        message+="falta sede\n";
        valido=false;
      }
      if(persona.tipo_rol === "0"){
        message+="falta rol\n";
        valido=false;
      }
      if(persona.ci.trim() === ""){
        message+="falta documento de identidad\n";
        valido=false;
      }
      if(persona.nombre_completo.trim() === ""){
        message+="falta nombre\n";
        valido=false;
      }
      if(persona.email.trim() === ""){
        message+="falta email\n";
        valido=false;
      }
      if(persona.genero.trim() === ""){
        message+="falta genero\n";
        valido=false;
      }
      if(valido){
        resolve(valido)
        
      }else{
        reject(message);
        //alert(message);
      }
    }).then(response =>{
      // fetchGuardar().then(()=>{
      //   message+="Guardado correctamente\n";
      // })
      //console.log("La respuesa es" + response);
      return fetchGuardar();
    })

  }
  const Reset=()=>{
    setPersona({...nuevaPersona});
    setBtnInterno(false);
  }

  const handleModalEstInterno = () =>{
    if(persona.sede === "0"){
      alert("Primero seleccione una sede");
      return;
    }
    modal.current.Abrir();
  }
  const handleModalEstInternoCerrar = ()=>{
    modal.current.Cerrar();
    estInternoRef.current.Reset();
  }
  const onEstudianteSelect = (estudianteUni) =>{
    modal.current.Cerrar();
    estInternoRef.current.Reset();
    setPersona(prev =>{
      let nuevo = {...prev};
      nuevo["ci"] = estudianteUni.ci;
      nuevo["nombre_completo"] = estudianteUni.nombre_completo;
      nuevo["carrera"] = estudianteUni.carrera;
      nuevo["email"] = estudianteUni.correo;
      return nuevo;
    });
  }
  if(PARAMETROS)
    return ( 
      <div className="container">
         {
          editar &&
          <FormularioRow >
            <FrLabelValue 
              label={"Sede:"} 
              component={
                <SelectRol 
                  data={PARAMETROS.SEDE} 
                  onChange={(e)=>handlePersona("sede",e)} 
                  first={{codigo:"0",valor:"Seleccionar"}} 
                  value={persona.sede} 
                />
              } />
            <FrLabelValue
              label={"Rol de la persona:"}
              component={<SelectRol titulo={"Rol de la persona:"} data={PARAMETROS.TIPO_ROL} onChange={handleSelectRol} first={{codigo:"0",valor:"Seleccionar"}} value={persona.tipo_rol} />}
            />
            {btnInterno &&
                <FrLabelValue
                label={""}
                component={
                  <button className="btn btn-primary" onClick={handleModalEstInterno}>Buscar estudiante interno...</button>
                }
              />
            }
            <FrLabelValue
              label={"Documento de identidad:"}
              component={<input onChange={e=>handlePersona("ci",e)} className="form-control" type="text" value={persona.ci}
              disabled ={persona.tipo_rol==="1"?"disabled":null}
              />}
            />
            <FrLabelValue
              label={"Nombre completo:"}
              component={<input onChange={e=>handlePersona("nombre_completo",e)} className="form-control" type="text" value={persona.nombre_completo}  />}
            />
            <FrLabelValue
              label={"Carrera:"}
              component={<input onChange={e=>handlePersona("carrera",e)} className="form-control" type="text" value={persona.carrera} 
              disabled ={persona.tipo_rol==="1"?"disabled":null}
              />}
            />
            <FrLabelValue
              label={"Género:"}
              component={
                <div key={persona.genero} className="form-group">
                  <div className="custom-control custom-radio">
                    <input className="custom-control-input" defaultChecked={persona.genero==="N"} value="N" type="radio" id="customRadio1" name="customRadio" onChange={(e)=>handlePersona("genero",e)} />
                    <label htmlFor="customRadio1" className="custom-control-label">Sin definir</label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input className="custom-control-input" defaultChecked={persona.genero==="M"} value="M" type="radio" id="customRadio2" name="customRadio" onChange={(e)=>handlePersona("genero",e)}  />
                    <label htmlFor="customRadio2" className="custom-control-label">Masculino</label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input className="custom-control-input" defaultChecked={persona.genero==="F"} value="F" type="radio" id="customRadio3" name="customRadio" onChange={(e)=>handlePersona("genero",e)} />
                    <label htmlFor="customRadio3" className="custom-control-label">Femenino</label>
                  </div>
                </div>
              }
            />
            <FrLabelValue
              label={"Correo electrónico:"}
              component={<input onChange={e=>handlePersona("email",e)} className="form-control" type="text" value={persona.email}  />}
            />
              {/* <FrLabelValue
                label={}
                component={}
              /> */}
          </FormularioRow>
          
          // <dl className="row">
          //   <dt className="col-sm-6">Sede</dt>
          //   <dd className="col-sm-6"><SelectRol data={PARAMETROS.SEDE} onChange={(e)=>handlePersona("sede",e)} first={{codigo:"0",valor:"Seleccionar"}} value={persona.sede} /></dd>

          //   <dt className="col-sm-6">Rol persona</dt>
          //   <dd className="col-sm-6">{persona.tipo_rol_valor}</dd>

          //   <dt className="col-sm-6">Documento de identidad</dt>
          //   <dd className="col-sm-6">{persona.ci}</dd>
          //   <dt className="col-sm-6">Nombre</dt>
          //   <dd className="col-sm-6">{persona.nombre_completo}</dd>
            
          //   <dt className="col-sm-6">Correo electrónico</dt>
          //   <dd className="col-sm-6">{persona.email}</dd>
          //   <dt className="col-sm-6">Genero</dt>
          //   <dd className="col-sm-6">{persona.genero}</dd>
            
          //   <dt className="col-sm-6">Creado en fecha</dt>
          //   <dd className="col-sm-6">{persona.created_at}</dd>
          // </dl>

         } 
        {
          !editar &&
          <FormularioRow>
            <FrLabelValue 
              label={"Sede:"} 
              component={ persona.sede_valor} />
            <FrLabelValue
              label={"Rol de la persona:"}
              component={persona.tipo_rol_valor}
            />
            <FrLabelValue
              label={"Documento de identidad:"}
              component={persona.ci}
            />
            <FrLabelValue
              label={"Nombre completo:"}
              component={persona.nombre_completo}
            />
            <FrLabelValue
              label={"Carrera:"}
              component={persona.carrera}
            />
            <FrLabelValue
              label={"Género:"}
              component={persona.genero}
            />
            <FrLabelValue
              label={"Correo electrónico:"}
              component={persona.email}
            />
            {/* <FrLabelValue
              label={"Fecha de creación:"}
              component={persona.created_at}
            /> */}
            <FrLabelValue
              label={"Creado por:"}
              component={<>{persona.creador}<br/>{persona.created_at}</>}
            />
            {/* <FrLabelValue
              label={"Fecha de modificación:"}
              component={persona.updated_at}
            /> */}
            <FrLabelValue
              label={"Modificado por:"}
              component={<>{persona.modificador}<br/>{persona.updated_at}</>}
            />
          </FormularioRow>
        }

        {/* <SelectRol titulo={"Sede:"} data={PARAMETROS.SEDE} onChange={(e)=>handlePersona("sede",e)} first={{codigo:"0",valor:"Seleccionar"}} value={persona.sede} />
        <SelectRol titulo={"Rol de la persona:"} data={PARAMETROS.TIPO_ROL} onChange={(e)=>handlePersona("tipo_rol",e)} first={{codigo:"0",valor:"Seleccionar"}} value={persona.tipo_rol} />
        <div className="form-group"></div>
        <label>Documento de identidad:</label>
        <input onChange={e=>handlePersona("ci",e)} className="form-control" type="text" value={persona.ci} />
        <label>Nombre completo:</label>
        <input onChange={e=>handlePersona("nombre_completo",e)} className="form-control" type="text" value={persona.nombre_completo}  />
        <label>Correo electrónico:</label>
        <input onChange={e=>handlePersona("email",e)} className="form-control" type="text" value={persona.email}  /> */}
        {showButtons && 
          <>
            <button className="btn btn-default"  onClick={Reset} >Reset</button>
            <button className="btn btn-primary" onClick={Guardar} >Guardar</button>
          </>
        }
        <Modal ref={modal} handleDialogoClose={handleModalEstInternoCerrar} titulo="Buscar estudiante interno" buttons={
          <>
            <button className="btn btn-secondary" onClick={handleModalEstInternoCerrar}>Cancelar</button>
          </>
        }>
          {persona.sede !== "0" &&
            <EstudianteInterno param ={PARAMETROS.SEDE.find(f => f.codigo === persona.sede) } onEstudianteSelect={onEstudianteSelect} ref={estInternoRef} />
          }
        </Modal>
      </div>
    );
})
 
export default FormPersona;